import { configureStore, combineReducers, Middleware } from '@reduxjs/toolkit';
import { useDispatch, useSelector, TypedUseSelectorHook } from 'react-redux';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import logger from 'redux-logger';
import authSlice from './auth.slice';
import drawerSlice from './drawer.slice';
import localesSlice from './locales.slice';
import accessControlSlice from './accessControl.slice';
import downloadSlice from './download.slice';
import uploadSlice from './upload.slice';
import categoriesSlice from './categories.slice';

const rootReducer = combineReducers({
  auth: authSlice,
  accessControl: accessControlSlice,
  drawer: drawerSlice,
  locales: localesSlice,
  download: downloadSlice,
  upload: uploadSlice,
  categories: categoriesSlice,
});

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist: ['auth'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const middlewareConfig = {
  serializableCheck: {
    ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
  },
};

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    process.env.NODE_ENV === 'development'
      ? getDefaultMiddleware(middlewareConfig).concat(logger as Middleware)
      : getDefaultMiddleware(middlewareConfig),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
