import { forwardRef, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Slide,
  TextField,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { TransitionProps } from '@mui/material/transitions';

import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { IAddCategory, ICategory, IUserControl } from 'interfaces';

type AddUserDialogProps = {
  open: boolean;
  editCategory: ICategory | null;
  onClose: () => void;
  onAdd: (data: IAddCategory) => void;
  onEdit: (data: IUserControl) => void;
  loading?: boolean;
};

const Transition = forwardRef(
  (
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
  ) => <Slide direction="up" ref={ref} {...props} />
);

Transition.displayName = 'Transition';

const schema = yup
  .object({
    name: yup.string().required('Campo obrigatório'),
  })
  .required();

const AddCategoryDialog = ({
  open,
  editCategory,
  onClose,
  onAdd,
  onEdit,
  loading,
}: AddUserDialogProps) => {
  const isEditMode = !!editCategory;

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<IAddCategory>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
    },
  });

  useEffect(() => {
    if (editCategory) {
      reset({
        name: editCategory.name,
      });
    } else {
      reset({
        name: '',
      });
    }
  }, [editCategory, reset, open]);

  const onSubmit: SubmitHandler<IAddCategory> = (data) => {
    if (editCategory) {
      onEdit({ ...editCategory, ...data } as IUserControl);
    } else {
      onAdd(data);
    }
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      fullWidth={true}
      maxWidth="xs"
      onClose={onClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>
        {isEditMode ? 'Editar categoria' : 'Nova categoria'}
      </DialogTitle>
      <DialogContent>
        <Controller
          name="name"
          control={control}
          render={({ field }) => {
            return (
              <TextField
                sx={{ width: '100%', marginTop: 2 }}
                label="Nome"
                {...field}
                error={!!errors.name}
                helperText={errors.name?.message}
              />
            );
          }}
        />
      </DialogContent>
      <DialogActions sx={{ marginBottom: 3, paddingRight: 3 }}>
        <Button
          onClick={() => {
            onClose();
          }}
        >
          Cancelar
        </Button>
        <LoadingButton
          variant="contained"
          onClick={() => handleSubmit(onSubmit)()}
          loading={loading}
        >
          {isEditMode ? 'Salvar' : 'Cadastrar'}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default AddCategoryDialog;
