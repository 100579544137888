import { Box, BoxProps, CSSObject, Theme, styled } from '@mui/material';
import { Outlet, useNavigate } from 'react-router-dom';

import { DownloadUpload, Drawer } from 'components';
import { useDrawer } from 'hooks';
import { useEffect } from 'react';

const openedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('padding-left', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('padding-left', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
});

interface StyledBoxProps extends BoxProps {
  open?: boolean;
}

const StyledBox = styled(Box)<StyledBoxProps>(({ theme, open }) => ({
  flex: 1,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
  }),
}));

const Home = () => {
  const { open, toggleDrawer } = useDrawer();
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/biblioteca');
  }, [navigate]);

  return (
    <Box display="flex">
      <Drawer open={open} setOpen={() => toggleDrawer()} />
      <StyledBox
        open={open}
        sx={{
          display: 'flex',
          flex: 1,
          overflow: 'auto',
        }}
      >
        <Outlet />
      </StyledBox>
      <DownloadUpload />
    </Box>
  );
};

export default Home;
