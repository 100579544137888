import { forwardRef, useRef, useState, useCallback, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Slide,
  IconButton,
  Stack,
  Typography,
  ButtonBase,
  Stepper,
  Box,
  Step,
  StepLabel,
  Button,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import {
  Close,
  CreateNewFolderOutlined,
  AddAPhotoOutlined,
} from '@mui/icons-material';

import { TransitionProps } from '@mui/material/transitions';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import AddressForm from './AddressForm';
import ContactForm from './ContactForm';
import { IAddressLocale, ICategory, IContactForm, ILocale } from 'interfaces';
import { useLocales, useUpload } from 'hooks';
import { CategoriesSelector, FileUpload } from 'components';
import { FileWithUrl } from 'components/FileUpload/FileUpload';

type AddLocaleProps = {
  isOpen: boolean;
  isEdit: boolean;
  onClose: () => void;
  onFinish: (loc: ILocale) => void;
};

const Transition = forwardRef(
  (
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
  ) => <Slide direction="up" ref={ref} {...props} />
);

Transition.displayName = 'Transition';

const addressSchema = yup
  .object({
    name: yup.string().required('Campo obrigatório'),
    zipCode: yup.string(),
    addressLine: yup.string(),
    number: yup.string(),
    neighborhood: yup.string(),
    state: yup.string(),
    city: yup.string(),
    price: yup.number(),
    observations: yup.string(),
  })
  .required();

const contactSchema = yup
  .object({
    name: yup.string(),
    role: yup.string(),
    phone: yup.string(),
    email: yup.string().email(),
  })
  .required();

const AddLocaleDialog = ({
  isOpen,
  isEdit,
  onClose,
  onFinish,
}: AddLocaleProps) => {
  const { startUpload } = useUpload();
  const [contacts, setContacts] = useState<IContactForm[]>([]);
  const [isEditContact, setIsEditContact] = useState(-1);
  const [locale, setLocale] = useState<IAddressLocale>();
  const [inputFiles, setInputFiles] = useState<FileWithUrl[]>([]);
  const [step, setStep] = useState(0);
  const [categories, setCategories] = useState<ICategory[]>([]);
  const dialogRef = useRef<HTMLDivElement>(null);

  const { createLocale, updateLocale, loading, localeInfo } = useLocales();

  const dialogClose = useCallback(() => {
    onClose();
    setTimeout(() => {
      setStep(0);
      setInputFiles([]);
    }, 300);
  }, [onClose]);

  const localeForm = useForm<IAddressLocale>({
    resolver: yupResolver(addressSchema),
  });

  const contactForm = useForm<IContactForm>({
    resolver: yupResolver(contactSchema),
  });

  const submitLocale = useCallback(() => {
    localeForm.handleSubmit((data) => {
      setLocale(data);
      setStep((p) => p + 1);
    })();
  }, [localeForm]);

  const submitContact = useCallback(() => {
    const auxContacts = [...contacts];

    const { name, role, phone, email } = contactForm.getValues();
    if (name || role || phone || email) {
      contactForm.handleSubmit((data) => {
        auxContacts.push(data);
      })();
    } else {
      contactForm.reset();
    }

    setContacts(auxContacts);
    setStep((p) => p + 1);
  }, [contactForm, contacts]);

  const submitButton = useCallback(() => {
    if (step === 0) {
      submitLocale();
    }
    if (step === 1) {
      submitContact();
    }
    if (step === 2) {
      if (!isEdit) {
        createLocale({ ...locale!, contacts, categories }, (newLocale) => {
          onFinish(newLocale);
          dialogClose();
        });
      } else {
        updateLocale({ ...locale!, contacts, categories }, dialogClose);
        if (inputFiles.length) {
          startUpload(
            localeInfo!.name,
            localeInfo!.id,
            localeInfo!.code,
            inputFiles
          );
        }
      }
    }
  }, [
    step,
    submitLocale,
    submitContact,
    isEdit,
    createLocale,
    locale,
    contacts,
    categories,
    onFinish,
    dialogClose,
    updateLocale,
    inputFiles,
    startUpload,
    localeInfo,
  ]);

  useEffect(() => {
    if (isEdit) {
      localeForm.reset({
        name: localeInfo!.name,
        zipCode: localeInfo!.address?.zipCode || '',
        addressLine: localeInfo!.address?.addressLine || '',
        complement: localeInfo!.address?.complement || '',
        city: localeInfo!.address?.city || '',
        neighborhood: localeInfo!.address?.neighborhood || '',
        number: localeInfo!.address?.number || '',
        observations: localeInfo!.observations,
        price: localeInfo!.price || 0,
        state: localeInfo!.address?.state || '',
      });

      contactForm.reset();
      setContacts(localeInfo!.contacts || []);
      setCategories(localeInfo!.categories || []);
    } else {
      localeForm.reset({
        name: '',
        zipCode: '',
        addressLine: '',
        complement: '',
        city: '',
        neighborhood: '',
        number: '',
        observations: '',
        price: 0,
        state: '',
      });
      contactForm.reset();
      setContacts([]);
      setCategories([]);
    }
  }, [localeInfo, contactForm, isOpen, localeForm, isEdit]);

  return (
    <Dialog
      ref={dialogRef}
      open={isOpen}
      TransitionComponent={Transition}
      fullWidth={true}
      maxWidth={step >= 0 ? 'lg' : 'sm'}
      onClose={dialogClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogActions
        sx={{
          justifyContent: 'space-between',
          padding: 0,
        }}
      >
        {step >= 0 ? (
          <Typography
            color="text.primary"
            variant="h6"
            padding={3}
            fontWeight={400}
          >
            {isEdit ? 'Editar local' : 'Novo local'}
          </Typography>
        ) : (
          <DialogTitle>{isEdit ? 'Atualizar' : 'Adicionar'}</DialogTitle>
        )}

        <IconButton onClick={dialogClose} sx={{ marginRight: 3 }}>
          <Close />
        </IconButton>
      </DialogActions>
      <DialogContent sx={{ paddingTop: 0 }}>
        {step >= 0 && (
          <Box sx={{ width: '100%' }}>
            <Stepper activeStep={step}>
              <Step completed={false}>
                <StepLabel>Endereço</StepLabel>
              </Step>
              <Step completed={false}>
                <StepLabel>Contato</StepLabel>
              </Step>
              <Step completed={false}>
                <StepLabel>Upload</StepLabel>
              </Step>
            </Stepper>
          </Box>
        )}
        {step === -1 && (
          <Stack>
            <ButtonBase
              onClick={() => setStep(0)}
              sx={{
                borderRadius: 1,
                border: '1px solid',
                borderColor: 'divider',
                boxShadow: 0,
                padding: 2,
                flexDirection: 'row',
              }}
            >
              <CreateNewFolderOutlined color="action" />
              <Stack
                sx={{ marginLeft: 2, width: '100%', alignItems: 'flex-start' }}
              >
                <Typography variant="h6">Novo Local</Typography>
                <Typography variant="body1">
                  Adicione todos os dados e fotos do novo local
                </Typography>
              </Stack>
            </ButtonBase>
            <ButtonBase
              sx={{
                mt: 2,
                borderRadius: 1,
                border: '1px solid',
                borderColor: 'divider',
                padding: 2,
                boxShadow: 0,
              }}
            >
              <AddAPhotoOutlined color="action" />
              <Stack
                sx={{ marginLeft: 2, width: '100%', alignItems: 'flex-start' }}
              >
                <Typography variant="h6">Upload de fotos</Typography>
                <Typography variant="body1">
                  Adicione ou atualize fotos de um local existente
                </Typography>
              </Stack>
            </ButtonBase>
          </Stack>
        )}
        {step === 0 && <AddressForm formMethods={localeForm} />}
        {step === 1 && (
          <ContactForm
            formMethods={contactForm}
            contacts={contacts}
            setContacts={setContacts}
            isEdit={isEditContact}
            setIsEdit={setIsEditContact}
          />
        )}
        {step === 2 && (
          <Box mt={4}>
            <CategoriesSelector
              selectedCategories={categories}
              setSelectedCategories={setCategories}
            />
            <FileUpload
              show={true}
              isFixed={true}
              inputFiles={inputFiles}
              setInputFiles={setInputFiles}
            />
          </Box>
        )}
      </DialogContent>
      {step >= 0 && (
        <DialogActions
          sx={{ flexDirection: 'row', justifyContent: 'space-between', px: 4 }}
        >
          <Button variant="text" onClick={dialogClose}>
            Cancelar
          </Button>
          <Stack flexDirection="row">
            {step >= 1 && (
              <Button variant="text" onClick={() => setStep((p) => p - 1)}>
                Voltar
              </Button>
            )}
            <LoadingButton
              variant="contained"
              sx={{ marginLeft: 2 }}
              onClick={submitButton}
              loading={loading}
            >
              {step === 2 ? 'Salvar' : 'Próximo'}
            </LoadingButton>
          </Stack>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default AddLocaleDialog;
