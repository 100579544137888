import { useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { ICategory } from 'interfaces';
import { useCategories } from 'hooks';

interface CategoriesSelectorProps {
  selectedCategories: ICategory[];
  setSelectedCategories: React.Dispatch<React.SetStateAction<ICategory[]>>;
}

const CategoriesSelector = ({
  selectedCategories,
  setSelectedCategories,
}: CategoriesSelectorProps) => {
  const [fetchCategories, setFetchCategories] = useState<ICategory[]>([]);

  const { getByName } = useCategories();

  return (
    <Autocomplete
      sx={{ px: 2 }}
      multiple
      id="tags"
      options={fetchCategories}
      defaultValue={selectedCategories}
      getOptionLabel={(option) => option.name}
      noOptionsText="Sem resultados"
      filterSelectedOptions
      onChange={(_, value) => {
        setSelectedCategories(value);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Tags"
          placeholder="Tags"
          onChange={(e) => {
            if (e.target.value.length > 2) {
              getByName(e.target.value, (data: ICategory[]) =>
                setFetchCategories(data)
              );
            }
          }}
        />
      )}
    />
  );
};

export default CategoriesSelector;
