import { useCallback, useState, useEffect, useRef } from 'react';
import { Add, SwapVertRounded } from '@mui/icons-material';
import {
  Box,
  Button,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';

import { MoreMenu } from 'components';
import { ICategory } from 'interfaces';
import { useCategories } from 'hooks';
import AddCategoryDialog from './AddCategoryDialog';
import DeleteCategoryDialog from './DeleteCategoryDialog';

const AccessControl = () => {
  const nameRef = useRef<HTMLInputElement>(null);

  const {
    categories,
    total,
    loading,
    getCategories,
    createCategory,
    updateCategory,
    deleteCategory,
  } = useCategories();

  const [page, setPage] = useState(0);
  const [name, setName] = useState('');
  const [sort, setSort] = useState('recent');
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [isAddCatDialogOpen, setIsAddCatDialogOpen] = useState(false);
  const [editCat, setEditCat] = useState<ICategory | null>(null);
  const [deleteCat, setDeleteCat] = useState<ICategory | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onEditCat = useCallback(
    (id: string) => {
      const cat = categories.find((cat) => cat.id === id);
      setEditCat(cat!);
      setIsAddCatDialogOpen(true);
    },
    [categories]
  );

  useEffect(() => {
    setPage(0);
  }, [sort, name]);

  useEffect(() => {
    getCategories(page, rowsPerPage, name, sort);
  }, [getCategories, page, rowsPerPage, name, sort]);

  return (
    <Box
      sx={{
        paddingY: 4,
        paddingX: 4,
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
      }}
    >
      <Stack
        direction="row"
        sx={{ marginBottom: 2 }}
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack direction="column" spacing={1}>
          <Typography variant="h4">Categorias</Typography>
          <Typography variant="body1">
            Gerencie e pesquise categorias
          </Typography>
        </Stack>
        <Button
          variant="contained"
          size="large"
          startIcon={<Add />}
          sx={{ height: 42 }}
          onClick={() => setIsAddCatDialogOpen(true)}
        >
          NOVA CATEGORIA
        </Button>
      </Stack>
      <Paper
        sx={{
          padding: 2,
          marginBottom: 2,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <Stack direction="row" width="100%">
          <TextField
            inputRef={nameRef}
            size="small"
            sx={{ flex: 1, marginRight: 2 }}
            label="Buscar"
          />
          <Button
            variant={name ? 'contained' : 'outlined'}
            size="small"
            sx={{ marginRight: 2, minWidth: 120 }}
            onClick={() => {
              if (name) {
                setName('');
                nameRef.current!.value = '';
              } else {
                setName(nameRef.current?.value || '');
              }
            }}
          >
            {name ? 'Limpar' : 'Buscar'}
          </Button>
          <Button
            id="order-button"
            variant="outlined"
            size="small"
            sx={{ marginRight: 2, minWidth: 120 }}
            aria-controls={open ? 'order-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            startIcon={<SwapVertRounded />}
          >
            Ordernar por
          </Button>
          <Menu
            id="order-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'order-button',
            }}
          >
            <MenuItem
              onClick={() => {
                setSort('recent');
                handleClose();
              }}
            >
              Recentes
            </MenuItem>
            <MenuItem
              onClick={() => {
                setSort('ascending');
                handleClose();
              }}
            >
              A-Z
            </MenuItem>
            <MenuItem
              onClick={() => {
                setSort('descending');
                handleClose();
              }}
            >
              Z-A
            </MenuItem>
          </Menu>
        </Stack>
      </Paper>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Categorias</TableCell>
              <TableCell align="right">Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {categories.map((cat) => (
              <TableRow
                key={cat.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell align="left">{cat.name}</TableCell>
                <TableCell align="right">
                  <MoreMenu
                    onEdit={() => onEditCat(cat.id)}
                    onDelete={() => setDeleteCat(cat)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25]}
        component="div"
        count={total}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(_: unknown, newPage: number) => setPage(newPage)}
        onRowsPerPageChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setRowsPerPage(parseInt(event.target.value, 10));
          setPage(0);
        }}
      />
      <AddCategoryDialog
        open={isAddCatDialogOpen}
        loading={loading}
        editCategory={editCat}
        onClose={() => {
          setIsAddCatDialogOpen(false);
          setEditCat(null);
        }}
        onAdd={(data) => {
          createCategory(data, () => {
            setPage(0);
            setSort('recent');
            getCategories(0, rowsPerPage, '', 'recent');
            setIsAddCatDialogOpen(false);
          });
        }}
        onEdit={(data) => {
          updateCategory(data, () => {
            getCategories(page, rowsPerPage);
            setIsAddCatDialogOpen(false);
          });
        }}
      />
      <DeleteCategoryDialog
        category={deleteCat}
        onClose={() => setDeleteCat(null)}
        onConfirm={() => {
          deleteCategory(deleteCat!, () => {
            setDeleteCat(null);
            getCategories(page, rowsPerPage);
          });
        }}
      />
    </Box>
  );
};

export default AccessControl;
