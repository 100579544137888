import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AllLocales, IGetLocale, IImage, ILocale } from 'interfaces';

interface LocaleState {
  page: number;
  total: number;
  lastPage: number;
  loading: boolean;
  loadingSearch: boolean;
  hasSearch: boolean;
  allLocales: AllLocales[];
  searchLocales: AllLocales[];
  searchTotal: number;
  loadingInfo: boolean;
  localeInfo: ILocale | null;
}

const initialState: LocaleState = {
  page: 0,
  total: 0,
  lastPage: 0,
  loading: false,
  loadingSearch: false,
  allLocales: [],
  searchLocales: [],
  searchTotal: 0,
  hasSearch: false,
  loadingInfo: false,
  localeInfo: null,
};

export const localesSlice = createSlice({
  name: 'locales',
  initialState,
  reducers: {
    SET_LOADING: (state, { payload }: { payload: boolean }) => {
      state.loading = payload;
    },
    SET_LOADING_SEARCH: (state, { payload }: { payload: boolean }) => {
      state.loadingSearch = payload;
      state.hasSearch = true;
    },
    SET_ALL_LOCALES: (state, action: PayloadAction<IGetLocale>) => {
      state.allLocales =
        action.payload.page === 1
          ? action.payload.locales
          : state.allLocales.concat(action.payload.locales);
      state.total = action.payload.total;
      state.lastPage = action.payload.lastPage;
      state.loading = false;
    },
    SET_SEARCH_LOCALES: (state, action: PayloadAction<IGetLocale>) => {
      state.searchLocales = action.payload.locales;
      state.loadingSearch = false;
      state.searchTotal = action.payload.total;
    },
    CLEAR_SEARCH_LOCALES: (state) => {
      state.searchLocales = [];
      state.searchTotal = 0;
      state.hasSearch = false;
    },
    SET_LOADING_INFO: (state, { payload }: { payload: boolean }) => {
      state.loadingInfo = payload;
    },
    SET_LOCALE_INFO: (state, { payload }) => {
      state.localeInfo = payload;
      state.loadingInfo = false;
    },
    SET_IMAGE_UPLOADED: (
      state,
      { payload }: { payload: { image: IImage; folderCode: string } }
    ) => {
      if (state.localeInfo && state.localeInfo.code === payload.folderCode) {
        state.localeInfo.images = [payload.image, ...state.localeInfo.images];
      }
    },
    SET_LOCALE_UPDATED: (state, { payload }: { payload: ILocale }) => {
      state.localeInfo = payload;
      state.loading = false;
    },
    SET_NEXT_PAGE: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    SET_LOCALE_CREATED: (state, { payload }: { payload: ILocale }) => {
      state.allLocales = state.allLocales.concat({
        id: payload.id,
        name: payload.name,
        code: payload.code,
      });
    },
  },
});

export const {
  SET_LOADING,
  SET_ALL_LOCALES,
  SET_LOADING_INFO,
  SET_LOCALE_INFO,
  SET_IMAGE_UPLOADED,
  SET_LOCALE_CREATED,
  SET_LOCALE_UPDATED,
  SET_LOADING_SEARCH,
  SET_SEARCH_LOCALES,
  CLEAR_SEARCH_LOCALES,
  SET_NEXT_PAGE,
} = localesSlice.actions;

export default localesSlice.reducer;
