import { useCallback, useEffect, useState } from 'react';
import {
  FolderOutlined,
  Folder,
  FileDownloadOutlined,
} from '@mui/icons-material';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import { useLocales } from 'hooks';
import { SearchLocale } from 'components';

const LocaleList = () => {
  const {
    allLocales,
    getAllLocales,
    getLocaleById,
    downloadFolders,
    hasSearch,
    searchTotal,
    page,
    lastPage,
    nextPage,
    localeInfo,
  } = useLocales();

  const [selectedLocales, setSelectedLocales] = useState<string[]>([]);

  const selectFolder = useCallback((id: string) => {
    setSelectedLocales((prev) => {
      if (prev.includes(id)) {
        return prev.filter((item) => item !== id);
      }
      return [...prev, id];
    });
  }, []);

  useEffect(() => {
    getAllLocales(0);
  }, [getAllLocales]);

  return (
    <Box
      sx={{
        minWidth: '300px',
        backgroundColor: 'common.white',
        position: 'relative',
        borderTopRightRadius: 24,
        boxShadow: 2,
      }}
    >
      <Stack
        width="300px"
        height="100vh"
        position="fixed"
        direction="column"
        top={0}
      >
        <Box padding={2}>
          <Typography variant="h5">Biblioteca</Typography>
          <SearchLocale />
          {selectedLocales.length > 0 && (
            <Button
              sx={{ marginBottom: 2 }}
              variant="contained"
              endIcon={<FileDownloadOutlined />}
              onClick={() => {
                downloadFolders(selectedLocales);
              }}
            >
              Baixar Pastas
            </Button>
          )}
          {hasSearch && (
            <Typography variant="caption">
              Exibindo {searchTotal} resultados
            </Typography>
          )}
          <Divider />
        </Box>
        <List
          id="scrollableDiv"
          sx={{
            width: '300px',
            overflowY: 'scroll',
            overflowX: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            marginX: 0,
            paddingBottom: 2,
            '&::-webkit-scrollbar': {
              width: 10,
            },
            '&::-webkit-scrollbar-track': {
              borderRadius: 10,
              backgroundColor: 'grey.100',
            },
            '&::-webkit-scrollbar-thumb': {
              borderRadius: '8px',
              backgroundColor: 'grey.800',
            },
          }}
        >
          <InfiniteScroll
            dataLength={allLocales.length}
            next={nextPage}
            style={{ display: 'flex', flexDirection: 'column' }}
            hasMore={!hasSearch ? page + 1 < lastPage : false}
            loader={
              <Stack p={2} width="100%" direction="row" justifyContent="center">
                <CircularProgress />
              </Stack>
            }
            scrollableTarget="scrollableDiv"
          >
            {allLocales.map((loc) => (
              <ListItem
                key={loc.id}
                dense
                disablePadding
                sx={{
                  '.MuiListItemIcon-root': {
                    minWidth: 40,
                  },
                }}
              >
                <ListItemButton
                  sx={{
                    color: 'grey.800',
                    '&.Mui-selected': { backgroundColor: 'grey.300' },
                    span: {
                      overflow: 'hidden',
                    },
                    '&:hover': {
                      span: {
                        whiteSpace: 'wrap',
                      },
                    },
                  }}
                  selected={loc.id === localeInfo?.id}
                  onClick={() => {
                    if (loc.id === localeInfo?.id) {
                      return;
                    }
                    getLocaleById(loc.id);
                  }}
                >
                  <ListItemIcon
                    onClick={(e) => {
                      e.stopPropagation();
                      selectFolder(loc.id);
                    }}
                  >
                    {selectedLocales.includes(loc.id) ? (
                      <Folder sx={{ color: 'grey.800' }} />
                    ) : (
                      <FolderOutlined sx={{ color: 'grey.800' }} />
                    )}
                  </ListItemIcon>
                  <ListItemText primary={loc.name} />
                </ListItemButton>
              </ListItem>
            ))}
          </InfiniteScroll>
        </List>
      </Stack>
    </Box>
  );
};

export default LocaleList;
