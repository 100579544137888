import {
  TextField,
  Box,
  Button,
  Divider,
  AlertTitle,
  Typography,
  IconButton,
} from '@mui/material';

import { Controller, SubmitHandler, UseFormReturn } from 'react-hook-form';
import { IContactForm } from 'interfaces';
import { Add, Delete, Edit } from '@mui/icons-material';
import { maskJs } from 'mask-js';

type ContactFormProps = {
  formMethods: UseFormReturn<IContactForm>;
  contacts: IContactForm[];
  setContacts: React.Dispatch<React.SetStateAction<IContactForm[]>>;
  isEdit: number;
  setIsEdit: React.Dispatch<React.SetStateAction<number>>;
};

const ContactForm = ({
  formMethods,
  contacts,
  setContacts,
  isEdit,
  setIsEdit,
}: ContactFormProps) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = formMethods;

  const onAddContact: SubmitHandler<IContactForm> = (data) => {
    if (isEdit !== -1) {
      setIsEdit(-1);
      setContacts((p) =>
        p.map((item, index) => {
          if (index === isEdit) {
            return { ...item, ...data };
          }
          return item;
        })
      );
    } else {
      setContacts((e) => e.concat(data));
    }
    reset({ name: '', role: '', phone: '', email: '' });
  };

  return (
    <Box>
      <Box display="flex" flexDirection="row" width="100%">
        <Controller
          name="name"
          control={control}
          render={({ field }) => {
            return (
              <TextField
                sx={{ flex: 1, width: '100%', marginTop: 2, marginRight: 2 }}
                label="Nome"
                {...field}
                value={field.value || ''}
                onChange={(e) => field.onChange(e.target.value)}
                error={!!errors.name}
                helperText={errors.name?.message}
              />
            );
          }}
        />
        <Controller
          name="role"
          control={control}
          render={({ field }) => {
            return (
              <TextField
                sx={{ flex: 1, marginTop: 2 }}
                label="Função/Cargo"
                {...field}
                value={field.value || ''}
                onChange={(e) => field.onChange(e.target.value)}
                error={!!errors.role}
                helperText={errors.role?.message}
              />
            );
          }}
        />
      </Box>

      <Box display="flex" flexDirection="row" width="100%">
        <Controller
          name="phone"
          control={control}
          render={({ field }) => {
            return (
              <TextField
                sx={{ flex: 1, marginTop: 2, marginRight: 2 }}
                label="Telefone"
                {...field}
                value={field.value || ''}
                onChange={(e) =>
                  field.onChange(maskJs('(99) 9999?9-9999', e.target.value))
                }
                error={!!errors.phone}
                helperText={errors.phone?.message}
              />
            );
          }}
        />
        <Controller
          name="email"
          control={control}
          render={({ field }) => {
            return (
              <TextField
                sx={{ flex: 1, marginTop: 2 }}
                label="Email"
                {...field}
                value={field.value || ''}
                onChange={(e) => field.onChange(e.target.value)}
                error={!!errors.email}
                helperText={errors.email?.message}
              />
            );
          }}
        />
      </Box>
      {isEdit !== -1 ? (
        <Button
          variant="contained"
          sx={{ marginTop: 2 }}
          onClick={handleSubmit(onAddContact)}
        >
          Salvar contato
        </Button>
      ) : (
        <Button
          variant="text"
          startIcon={<Add />}
          sx={{ marginTop: 2 }}
          onClick={handleSubmit(onAddContact)}
        >
          Adicionar outro contato
        </Button>
      )}

      {contacts.length > 0 && (
        <Box>
          <Divider sx={{ marginTop: 2 }} />
          {contacts.map((contact, index) => (
            <>
              <Box
                key={contact.phone}
                display="flex"
                flexDirection="row"
                alignItems="center"
              >
                <Box
                  sx={{
                    flex: 1,
                    marginTop: 2,
                    marginRight: 2,
                    marginBottom: 2,
                  }}
                >
                  <AlertTitle>{contact.name}</AlertTitle>
                  <Box flexDirection="row" display="flex" flex={1}>
                    <Typography variant="body2">{contact.role}</Typography>
                    <Divider
                      orientation="vertical"
                      sx={{ height: 20, marginRight: 2, marginLeft: 2 }}
                    />
                    <Typography variant="body2">{contact.phone}</Typography>
                    <Divider
                      orientation="vertical"
                      sx={{ height: 20, marginRight: 2, marginLeft: 2 }}
                    />
                    <Typography variant="body2">{contact.email}</Typography>
                  </Box>
                </Box>
                <Box display="flex" flexDirection="row">
                  <IconButton
                    aria-label="edit"
                    size="small"
                    onClick={() => {
                      reset(contact);
                      setIsEdit(index);
                    }}
                  >
                    <Edit />
                  </IconButton>
                  <IconButton
                    aria-label="delete"
                    size="small"
                    onClick={() =>
                      setContacts((p) =>
                        p.filter((_, itemIndex) => itemIndex !== index)
                      )
                    }
                  >
                    <Delete />
                  </IconButton>
                </Box>
              </Box>
              <Divider sx={{ width: '100%' }} />
            </>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default ContactForm;
