import { useState, useRef, useEffect, useCallback } from 'react';
import {
  TextField,
  FormControlLabel,
  Box,
  Typography,
  Slide,
  Checkbox,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import * as S from './Login.styles';
import { useAuth } from 'hooks';
import { IFirstAccess, ILogin } from 'interfaces';

import { useNavigate } from 'react-router-dom';

const schema = yup
  .object({
    email: yup
      .string()
      .email('Digite um email válido')
      .required('Campo obrigatório'),
    password: yup
      .string()
      .min(6, 'A senha precisa ter pelo menos 8 dígitos')
      .required('Campo obrigatório'),
  })
  .required();

const newPasswordSchema = yup
  .object({
    password: yup
      .string()
      .min(6, 'A senha precisa ter pelo menos 8 dígitos')
      .required('Campo obrigatório'),
    passwordConfirmation: yup
      .string()
      .oneOf([yup.ref('password')], 'As senhas não coincidem')
      .required('Campo obrigatório'),
  })
  .required();

const Login = () => {
  const navigate = useNavigate();
  const [hideLogin, setHideLogin] = useState<boolean>(false);
  const [showNewUser, setNewUser] = useState<boolean>(false);
  const [saveInfo, setSaveInfo] = useState<boolean>(false);
  const [showPw, setShowPw] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const { user, loading, error, login, firstAccess } = useAuth();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const {
    handleSubmit: npHandleSubmit,
    control: npControl,
    formState: { errors: npErrors },
  } = useForm({
    resolver: yupResolver(newPasswordSchema),
  });

  const onSubmit: SubmitHandler<ILogin> = useCallback(
    (data) => {
      login({ ...data, saveInfo }, () => navigate('/biblioteca'));
    },
    [login, navigate, saveInfo]
  );

  const onNpSubmit: SubmitHandler<IFirstAccess> = useCallback(
    (data) => {
      firstAccess(data.password, () => navigate('/biblioteca'));
    },
    [firstAccess, navigate]
  );

  useEffect(() => {
    if (user?.firstAccess) {
      setHideLogin(true);
    }
  }, [user]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      switch (event.key) {
        case 'Enter':
          event.preventDefault();
          if (showPw) {
            npHandleSubmit(onNpSubmit)();
          } else {
            handleSubmit(onSubmit)();
          }
          break;
        default:
          break;
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleSubmit, npHandleSubmit, onNpSubmit, onSubmit, showPw]);

  return (
    <S.Container>
      <S.BGImage>
        <Typography variant="h3" color="white">
          Bem vindo!
        </Typography>
        <Typography variant="body1" color="lightgray">
          Nossa equipe traz um formato único no serviço de produção de locação.
          Cada um de nós atua na área em que tem mais habilidade, formando um
          time forte. Somos do tamanho que o seu projeto precisar, seja com uma
          pessoa ou com todo o time. Você sempre terá alguém para te atender.
          Esse é nosso compromisso.
        </Typography>
      </S.BGImage>
      <S.LoginContainer ref={containerRef}>
        <Slide
          direction="right"
          in={!hideLogin}
          container={containerRef.current}
          mountOnEnter
          unmountOnExit
          onExited={() => setNewUser(true)}
        >
          <S.LoginArea>
            <Box sx={{ marginBottom: '64px' }}>
              <span className="logo">ELOCS</span>
            </Box>
            <Typography variant="h5" mb="16px">
              Login
            </Typography>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextField
                  label="Email"
                  margin="dense"
                  {...field}
                  value={field.value || ''}
                  onChange={(e) => field.onChange(e.target.value)}
                  error={!!errors.email || error}
                />
              )}
            />
            <Controller
              name="password"
              control={control}
              render={({ field }) => {
                return (
                  <TextField
                    label="Senha"
                    margin="dense"
                    type={showPw ? 'text' : 'password'}
                    {...field}
                    value={field.value || ''}
                    onChange={(e) => field.onChange(e.target.value)}
                    error={!!errors.password || error}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPw((v) => !v)}
                          >
                            {showPw ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                );
              }}
            />
            {error && (
              <Typography variant="body2" color="error" margin={2} ml={0}>
                Email ou senha inválidos
              </Typography>
            )}
            <Box sx={{ padding: '8px 16px', margin: '16px 0px' }}>
              <FormControlLabel
                control={<Checkbox color="primary" />}
                labelPlacement="end"
                checked={saveInfo}
                onChange={() => setSaveInfo((v) => !v)}
                label="Permanecer conectado"
              />
            </Box>
            <LoadingButton
              variant="contained"
              color="primary"
              loading={loading}
              size="large"
              onClick={() => handleSubmit(onSubmit)()}
            >
              Entrar
            </LoadingButton>
          </S.LoginArea>
        </Slide>
        <Slide
          direction="left"
          in={showNewUser}
          container={containerRef.current}
          mountOnEnter
          unmountOnExit
        >
          <S.LoginArea>
            <Typography variant="h5" mb="16px">
              Crie uma nova senha
            </Typography>
            <Controller
              name="password"
              control={npControl}
              render={({ field }) => (
                <TextField
                  label="Senha"
                  margin="dense"
                  type={showPw ? 'text' : 'password'}
                  {...field}
                  error={!!npErrors.password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPw((v) => !v)}
                        >
                          {showPw ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
            <Controller
              name="passwordConfirmation"
              control={npControl}
              render={({ field }) => (
                <TextField
                  label="Senha"
                  margin="dense"
                  type={showPw ? 'text' : 'password'}
                  {...field}
                  error={!!npErrors.passwordConfirmation}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPw((v) => !v)}
                        >
                          {showPw ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
            <LoadingButton
              sx={{ marginTop: 2 }}
              variant="contained"
              color="primary"
              size="large"
              loading={loading}
              onClick={() => npHandleSubmit(onNpSubmit)()}
            >
              Redefinir senha
            </LoadingButton>
          </S.LoginArea>
        </Slide>
      </S.LoginContainer>
    </S.Container>
  );
};

export default Login;
