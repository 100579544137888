import { useCallback, useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { IAddCategory, ICategory, IGetCategory } from 'interfaces';
import debounce from 'lodash.debounce';
import { enqueueSnackbar } from 'notistack';
import { api } from 'api';
import {
  DELETE_CATEGORY,
  SET_CATEGORIES_SUCCESS,
  SET_LOADING,
  UPDATE_CATEGORY,
} from 'store/categories.slice';

const useCategories = () => {
  const dispatch = useAppDispatch();

  const { categories, total, lastPage, loading } = useAppSelector(
    ({ categories }) => categories
  );

  const getCategories = useCallback(
    async (page?: number, limit?: number, name?: string, sort?: string) => {
      try {
        SET_LOADING(true);
        const { data } = await api.get<IGetCategory>('/categories', {
          params: { page: (page || 0) + 1, limit, name, sort },
        });
        dispatch(SET_CATEGORIES_SUCCESS(data));
      } catch (error) {
        enqueueSnackbar('Erro ao buscar categorias', {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    },
    [dispatch]
  );

  const createCategory = useCallback(
    async (cat: IAddCategory, successCallback?: () => void) => {
      try {
        SET_LOADING(true);
        await api.post<ICategory>('/categories/create', cat);
        dispatch(SET_LOADING(false));
        successCallback?.();
      } catch (error) {
        enqueueSnackbar('Erro ao criar categoria', {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    },
    [dispatch]
  );

  const updateCategory = useCallback(
    async (cat: ICategory, successCallback?: () => void) => {
      try {
        SET_LOADING(true);
        const { data } = await api.patch<ICategory>(
          `/categories/${cat.id}/update`,
          cat
        );
        dispatch(UPDATE_CATEGORY(data));
        successCallback?.();
      } catch (error) {
        enqueueSnackbar('Erro ao atualizar categoria', {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    },
    [dispatch]
  );

  const deleteCategory = useCallback(
    async (cat: ICategory, successCallback?: () => void) => {
      try {
        SET_LOADING(true);
        const { data } = await api.delete(`/categories/${cat.id}/delete`);
        dispatch(DELETE_CATEGORY(data));
        successCallback?.();
      } catch (error) {
        enqueueSnackbar('Erro ao deletar categoria', {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    },
    [dispatch]
  );

  const getByName = useRef(
    debounce(
      async (name?: string, successCallback?: (cats: ICategory[]) => void) => {
        try {
          const { data } = await api.get<ICategory[]>('/categories/all', {
            params: { name },
          });
          successCallback?.(data);
        } catch (error) {
          enqueueSnackbar('Erro ao buscar categorias', {
            variant: 'error',
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          });
        }
      },
      300
    )
  ).current;

  return {
    categories,
    total,
    lastPage,
    loading,
    getCategories,
    createCategory,
    updateCategory,
    deleteCategory,
    getByName,
  };
};

export default useCategories;
