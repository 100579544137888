import axios from 'axios';

export const api = axios.create({
  baseURL: process.env.REACT_APP_URL_HOST,
});

api.interceptors.request.use(
  (config) => {
    const auth = localStorage.getItem('auth');
    if (!config.headers.Authorization && auth) {
      const { token } = JSON.parse(auth);
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === 401) {
      localStorage.removeItem('auth');
      window.location.href = '/login';
    } else {
      return Promise.reject(error);
    }
  }
);

export const setToken = (token: string) => {
  if (token) {
    api.defaults.headers.Authorization = `Bearer ${token}`;
  } else {
    api.defaults.headers.Authorization = '';
  }
};
