import { styled, Box } from '@mui/material';

import bg from '../../assets/loginBg.png';

export const Container = styled(Box)`
  flex: 1;
  height: 100vh;
  display: flex;
  flex-direction: row;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const BGImage = styled('div')`
  width: calc(100vw - 400px);
  max-width: 66vw;
  height: auto;
  object-fit: cover;
  z-index: 100;
  padding: ${({ theme }) => theme.spacing(8)};

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 10px;

  border-radius: var(--none, 0px);
  background: linear-gradient(
      0deg,
      var(--components-backdrop-fill, rgba(0, 0, 0, 0.5)) 0%,
      var(--components-backdrop-fill, rgba(0, 0, 0, 0.5)) 100%
    ),
    url(${bg}), lightgray 50% / cover no-repeat;
  background-size: cover;

  h3,
  p {
    max-width: 66%;
  }

  @media (max-width: 1048px) {
    padding: 0px;
    h3,
    p {
      display: none;
    }
  }

  @media (max-width: 768px) {
    height: 100%;
    max-width: 100vw;
    max-height: 33vh;
    width: 100%;
  }

  @media (max-width: 400px) {
    max-width: unset;
  }
`;

export const LoginContainer = styled(Box)`
  min-width: 400px;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing(2)};
  background-color: ${({ theme }) => theme.palette.background.default};

  @media (max-width: 768px) and (min-width: 400px) {
    padding-left: calc((100% - 400px) / 2);
    padding-right: calc((100% - 400px) / 2);
  }

  @media (max-width: 400px) {
    min-width: 0px;
  }

  .logo {
    color: var(--text-primary, rgba(0, 0, 0, 0.87));
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Fraunces;
    font-size: 49.982px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 1.25px;
    text-transform: uppercase;
  }
`;

export const LoginArea = styled(Box)`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
`;
