import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { enqueueSnackbar } from 'notistack';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import plimit from 'p-limit';

import {
  SET_ALL_LOCALES,
  SET_LOADING,
  SET_LOADING_INFO,
  SET_LOCALE_CREATED,
  SET_LOCALE_INFO,
  SET_LOCALE_UPDATED,
  SET_LOADING_SEARCH,
  SET_SEARCH_LOCALES,
  SET_NEXT_PAGE,
} from 'store/locales.slice';

import {
  INIT_DOWNLOAD,
  FINISH_DOWNLOAD,
  UPDATE_PERCENTAGE,
} from 'store/download.slice';

import { api } from 'api';
import {
  IAddressLocale,
  ICategory,
  IContactForm,
  ICreateLocale,
  IGetLocale,
  ILocale,
  IUpdateLocale,
} from 'interfaces';
import dayjs from 'dayjs';

const useLocales = () => {
  const dispatch = useAppDispatch();

  const {
    page,
    lastPage,
    total,
    loading,
    loadingSearch,
    searchLocales,
    searchTotal,
    hasSearch,
    allLocales,
    loadingInfo,
    localeInfo,
  } = useAppSelector(({ locales }) => locales);

  const getAllLocales = useCallback(
    async (page?: number) => {
      dispatch(SET_LOADING(true));
      try {
        const { data } = await api.get<IGetLocale>('/locales', {
          params: {
            limit: 100,
            page: (page || 0) + 1,
          },
        });

        dispatch(SET_ALL_LOCALES({ ...data, page: (page || 0) + 1 }));
      } catch (error) {
        dispatch(SET_LOADING(false));
        enqueueSnackbar('Erro ao buscar locais', {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    },
    [dispatch]
  );

  const getLocaleById = useCallback(
    async (id: string) => {
      dispatch(SET_LOADING_INFO(true));
      try {
        const { data } = await api.get<ILocale>(`/locales/${id}`);
        dispatch(SET_LOCALE_INFO(data));
      } catch (error) {
        dispatch(SET_LOADING_INFO(false));
        enqueueSnackbar('Erro ao buscar local', {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    },
    [dispatch]
  );

  const getByName = useCallback(
    async (name?: string, categories?: ICategory[], page?: number) => {
      dispatch(SET_LOADING_SEARCH(true));
      try {
        const { data } = await api.get<IGetLocale>('/locales', {
          params: {
            categories: categories?.map((cat) => cat.id),
            name,
            limit: 1000,
            page: (page || 0) + 1,
          },
        });

        if (categories?.length) {
          localStorage.setItem(
            'recent-categories',
            JSON.stringify(categories.slice(0, 5))
          );
        }

        if (data.locales.length > 0) {
          getLocaleById(data.locales[0].id);
        }

        dispatch(SET_SEARCH_LOCALES(data));
      } catch (error) {
        dispatch(SET_LOADING_SEARCH(false));
        enqueueSnackbar('Erro ao buscar locais', {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    },
    [dispatch, getLocaleById]
  );

  const downloadImages = useCallback(
    async (imgsIds: string[]) => {
      try {
        dispatch(INIT_DOWNLOAD(localeInfo?.code || ''));
        const { data } = await api.post<{
          imgList: { url: string; name: string }[];
        }>(`/locales/${localeInfo?.id}/download`, imgsIds);

        const zip = new JSZip();
        const limit = plimit(20);

        const imagePromises = data.imgList.map(({ url, name }) =>
          limit(async () => {
            const response = await fetch(url);
            const blob = await response.blob();
            zip.file(name, blob);
          })
        );

        await Promise.all(imagePromises);

        zip.generateAsync({ type: 'blob' }).then((content) => {
          saveAs(
            content,
            `elocs_${localeInfo?.code}_${dayjs().format('DDMMYY')}.zip`
          );
        });

        dispatch(FINISH_DOWNLOAD());
      } catch (error) {
        enqueueSnackbar('Erro ao baixar imagens', {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    },
    [localeInfo, dispatch]
  );

  const downloadFolders = useCallback(
    async (ids: string[]) => {
      try {
        const sizeResponse = await api.post('/locales/download-folders/size', {
          ids,
        });

        const estimatedLength = parseInt(
          sizeResponse.headers['x-content-length']
        );
        dispatch(INIT_DOWNLOAD('Múltiplas Pastas'));
        const { data } = await api.post<{
          imgList: { url: string; name: string }[];
        }>('/locales/download-folders', { ids });

        const zip = new JSZip();
        const limit = plimit(20);

        const imagePromises = data.imgList.map(({ url, name }) =>
          limit(async () => {
            const response = await fetch(url);
            const blob = await response.blob();
            dispatch(
              UPDATE_PERCENTAGE({
                downloaded: blob.size,
                total: estimatedLength,
              })
            );
            zip.file(name, blob);
          })
        );

        await Promise.all(imagePromises);

        zip.generateAsync({ type: 'blob' }).then((content) => {
          saveAs(
            content,
            `elocs_0${ids.length}_${dayjs().format('DDMMYY')}.zip`
          );
        });

        dispatch(FINISH_DOWNLOAD());
      } catch (error) {
        enqueueSnackbar('Erro ao baixar imagens', {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    },
    [dispatch]
  );

  const createLocale = useCallback(
    async (
      data: IAddressLocale & {
        contacts: IContactForm[];
        categories: ICategory[];
      },
      successCallback?: (loc: ILocale) => void
    ) => {
      dispatch(SET_LOADING(true));

      const body: ICreateLocale = {
        name: data.name || '',
        photographer: '',
        observations: data.observations,
        address: {
          zipCode: data.zipCode || '',
          addressLine: data.addressLine || '',
          number: data.number || '',
          state: data.state || '',
          city: data.city || '',
          neighborhood: data.neighborhood || '',
        },
        price: data.price || 0,
        contacts: data.contacts.map((c) => ({
          name: c.name || '',
          role: c.role || '',
          phone: c.phone || '',
          email: c.email || '',
        })),
        categories: data.categories.map((cat) => cat.id),
        images: [],
      };

      try {
        const response = await api.post('/locales/create', body);

        successCallback?.(response.data);
        dispatch(SET_LOCALE_CREATED(response.data));
      } catch (error) {
        dispatch(SET_LOADING(false));
        enqueueSnackbar('Erro ao criar local', {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    },
    [dispatch]
  );

  const updateLocale = useCallback(
    async (
      data: IAddressLocale & {
        contacts: IContactForm[];
        categories: ICategory[];
      },
      successCallback?: (loc: ILocale) => void
    ) => {
      dispatch(SET_LOADING(true));

      const body: IUpdateLocale = {
        name: data.name || '',
        photographer: localeInfo!.photographer || '',
        observations: data.observations,
        address: {
          zipCode: data.zipCode || '',
          addressLine: data.addressLine || '',
          complement: data.complement || '',
          number: data.number || '',
          state: data.state || '',
          city: data.city || '',
          neighborhood: data.neighborhood || '',
          id: localeInfo!.address?.id || '',
        },
        price: data.price || 0,
        contacts: data.contacts.map((c) => ({
          name: c.name || '',
          role: c.role || '',
          phone: c.phone || '',
          email: c.email || '',
          id: c.id,
        })),
        categories: data.categories.map((cat) => cat.id),
        images: [],
        id: localeInfo!.id,
      };

      try {
        const response = await api.patch(
          `/locales/${localeInfo!.id}/update`,
          body
        );

        dispatch(SET_LOCALE_UPDATED(response.data));
        successCallback?.(response.data);
      } catch (error) {
        dispatch(SET_LOADING(false));
        enqueueSnackbar('Erro ao atualizar local', {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    },
    [dispatch, localeInfo]
  );

  const nextPage = useCallback(() => {
    dispatch(SET_NEXT_PAGE(page + 1));
    getAllLocales(page + 1);
  }, [dispatch, page, getAllLocales]);

  return {
    getAllLocales,
    getLocaleById,
    downloadImages,
    downloadFolders,
    createLocale,
    updateLocale,
    getByName,
    nextPage,
    loading,
    loadingSearch,
    searchTotal,
    hasSearch,
    allLocales: hasSearch ? searchLocales : allLocales,
    loadingInfo,
    localeInfo,
    page,
    lastPage,
    total,
  };
};

export default useLocales;
