import { useCallback, useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { useSnackbar } from 'notistack';
import {
  INIT_UPLOAD,
  UPDATE_PROGRESS,
  UPLOAD_ERROR,
  UPLOAD_STARTED,
  UPLOAD_SUCCESS,
} from 'store/upload.slice';
import { FileWithUrl } from 'components/FileUpload/FileUpload';
import { api } from 'api';
import { IImage } from 'interfaces';
import { SET_IMAGE_UPLOADED } from 'store/locales.slice';
import { Promise as BluebirdPromise } from 'bluebird';

const useUpload = () => {
  const { enqueueSnackbar } = useSnackbar();
  const controllerRef = useRef(new AbortController());
  const dispatch = useAppDispatch();

  const { folders, open } = useAppSelector(({ upload }) => upload);
  const { localeInfo } = useAppSelector(({ locales }) => locales);

  const startUpload = useCallback(
    async (
      folderName: string,
      localeId: string,
      folderCode: string,
      files: FileWithUrl[]
    ) => {
      dispatch(
        INIT_UPLOAD({
          folderName,
          folderCode,
          folderId: localeId,
          images: files.map((file) => ({
            loading: false,
            uploaded: false,
            name: file.file.name,
            code: file.file.name.replace(/\D/g, ''),
            url: file.url,
            error: false,
            progress: 0,
          })),
        })
      );

      await BluebirdPromise.map(
        files,
        async (file) => {
          try {
            dispatch(
              UPLOAD_STARTED({
                fileName: file.file.name,
                folderCode,
              })
            );
            const formData = new FormData();
            formData.append('file', file.file);

            const response = await api.post<IImage>(
              `locales/${localeId}/upload`,
              formData,
              {
                signal: controllerRef.current.signal,
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
                onUploadProgress: (progress) => {
                  dispatch(
                    UPDATE_PROGRESS({
                      fileName: file.file.name,
                      folderCode,
                      progress: Math.round(
                        (progress.loaded / file.file.size) * 100
                      ),
                    })
                  );
                },
              }
            );

            dispatch(UPLOAD_SUCCESS({ fileName: file.file.name, folderCode }));

            dispatch(
              SET_IMAGE_UPLOADED({
                image: {
                  id: response.data.id,
                  createdAt: response.data.createdAt,
                  thumbnail: response.data.thumbnail,
                  code: response.data.code,
                  name: response.data.name,
                  url: response.data.url,
                },
                folderCode,
              })
            );
            URL.revokeObjectURL(file.url);
          } catch (err) {
            enqueueSnackbar('Erro ao fazer upload da imagem', {
              variant: 'error',
              anchorOrigin: { vertical: 'top', horizontal: 'right' },
            });
            dispatch(UPLOAD_ERROR({ fileName: file.file.name, folderCode }));
          }
        },
        { concurrency: 4 }
      );
    },
    [dispatch, enqueueSnackbar]
  );

  return {
    startUpload,
    folders: folders.map((f) => ({
      folderName: f.folderName,
      folderCode: f.folderCode,
      total: f.images.length,
      uploaded: f.images.filter((i) => i.uploaded).length,
    })),
    openUpload: open,
    total: folders.reduce((acc, f) => acc + f.images.length, 0),
    uploaded:
      folders
        .find((f) => f.folderCode === localeInfo?.code)
        ?.images.filter((i) => i.uploaded).length || 0,
    images:
      folders
        .find((f) => f.folderCode === localeInfo?.code)
        ?.images.filter((i) => !i.uploaded) || [],
    errors: folders.reduce<{ fileName: string; folderCode: string }[]>(
      (acc, f) => {
        const errors = f.images.filter((i) => i.error);
        if (errors.length) {
          acc.concat(
            errors.map((e) => ({
              fileName: e.name,
              folderCode: f.folderCode,
            }))
          );
        }
        return acc;
      },
      []
    ),
  };
};

export default useUpload;
