import { forwardRef, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Slide,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { TransitionProps } from '@mui/material/transitions';
import { red } from '@mui/material/colors';

import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { IAddUser, UserRoles } from 'interfaces/accessControl.interfaces';
import { IUserControl } from 'interfaces';
import { useAccessControl } from 'hooks';

type AddUserDialogProps = {
  open: boolean;
  editUser: IUserControl | null;
  onClose: () => void;
  onAdd: (data: IAddUser) => void;
  onEdit: (data: IUserControl) => void;
  loading?: boolean;
};

const Transition = forwardRef(
  (
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
  ) => <Slide direction="up" ref={ref} {...props} />
);

Transition.displayName = 'Transition';

const schema = yup
  .object({
    name: yup.string().required('Campo obrigatório'),
    email: yup
      .string()
      .email('Digite um email válido')
      .required('Campo obrigatório'),
    role: yup.string().required('Campo obrigatório'),
  })
  .required();

const AddUserDialog = ({
  open,
  editUser,
  onClose,
  onAdd,
  onEdit,
}: AddUserDialogProps) => {
  const isEditMode = !!editUser;
  const { loading } = useAccessControl();

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<IAddUser>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
      email: '',
      role: '',
    },
  });

  useEffect(() => {
    if (editUser) {
      reset({
        name: editUser.name,
        email: editUser.email,
        role: editUser.role,
      });
    } else {
      reset({
        name: '',
        email: '',
        role: '',
      });
    }
  }, [editUser, reset, open]);

  const onSubmit: SubmitHandler<IAddUser> = (data) => {
    if (editUser) {
      onEdit({ ...editUser, ...data } as IUserControl);
    } else {
      onAdd(data);
    }
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      fullWidth={true}
      maxWidth="xs"
      onClose={onClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>
        {isEditMode ? 'Editar usuário' : 'Novo usuário'}
      </DialogTitle>
      <DialogContent>
        <Controller
          name="name"
          control={control}
          render={({ field }) => {
            return (
              <TextField
                sx={{ width: '100%', marginTop: 2 }}
                label="Nome"
                {...field}
                error={!!errors.name}
                helperText={errors.name?.message}
              />
            );
          }}
        />
        <Controller
          name="email"
          control={control}
          render={({ field }) => {
            return (
              <TextField
                sx={{ width: '100%', marginTop: 2 }}
                label="Email"
                {...field}
                error={!!errors.email}
                helperText={errors.email?.message}
              />
            );
          }}
        />
        <Controller
          name="role"
          control={control}
          render={({ field }) => {
            return (
              <FormControl fullWidth sx={{ marginTop: 2 }}>
                <InputLabel id="role-label">Função</InputLabel>
                <Select
                  {...field}
                  labelId="role-label"
                  label="Função"
                  sx={{ width: '100%' }}
                  error={!!errors.role}
                  aria-describedby="role-helper-text"
                >
                  {(
                    Object.keys(UserRoles) as Array<keyof typeof UserRoles>
                  ).map((item) => (
                    <MenuItem key={item} value={UserRoles[item]}>
                      {UserRoles[item]}
                    </MenuItem>
                  ))}
                </Select>
                {errors.role?.message && (
                  <FormHelperText
                    id="role-helper-text"
                    sx={{ color: red[700] }}
                  >
                    {errors.role.message}
                  </FormHelperText>
                )}
              </FormControl>
            );
          }}
        />
      </DialogContent>
      <DialogActions sx={{ marginBottom: 3, paddingRight: 3 }}>
        <Button
          onClick={() => {
            onClose();
          }}
        >
          Cancelar
        </Button>
        <LoadingButton
          variant="contained"
          onClick={() => handleSubmit(onSubmit)()}
          loading={loading}
        >
          {isEditMode ? 'Salvar' : 'Cadastrar'}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default AddUserDialog;
