import { UserRoles } from 'interfaces';
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAppSelector } from 'store';

type ProtectedRouteProps = {
  component: JSX.Element;
  requiresAdminAuth?: boolean;
};

const ProtectedRoute = ({
  component,
  requiresAdminAuth,
}: ProtectedRouteProps) => {
  const { user } = useAppSelector(({ auth }) => auth);

  const isAuthenticated = user !== null && !user?.firstAccess;
  const isAuthorized = user?.role === UserRoles.ADMIN;

  const location = useLocation();

  if (isAuthenticated) {
    if (requiresAdminAuth && !isAuthorized) {
      return <Navigate to="/" state={{ from: location }} />;
    }

    return component;
  } else {
    return <Navigate to="/login" state={{ from: location }} />;
  }
};

export default ProtectedRoute;
