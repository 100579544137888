import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { TransitionProps } from '@mui/material/transitions';
import { forwardRef } from 'react';
import { useAccessControl } from 'hooks';

type ChangePasswordDialogProps = {
  open: boolean;
  onClose: () => void;
  onChange: () => void;
};

const Transition = forwardRef(
  (
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
  ) => <Slide direction="up" ref={ref} {...props} />
);

Transition.displayName = 'Transition';

const ChangePasswordDialog = ({
  open,
  onClose,
  onChange,
}: ChangePasswordDialogProps) => {
  const { loading } = useAccessControl();
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      fullWidth={true}
      maxWidth="xs"
      onClose={onClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>Alterar senha</DialogTitle>
      <DialogContent>A senha será alterada para a senha padrão.</DialogContent>
      <DialogActions sx={{ marginBottom: 3, paddingRight: 3 }}>
        <Button
          onClick={() => {
            onClose();
          }}
        >
          Cancelar
        </Button>
        <LoadingButton
          variant="contained"
          onClick={() => onChange()}
          loading={loading}
        >
          Salvar
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ChangePasswordDialog;
