import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { IUser } from 'interfaces';

interface AuthState {
  user: IUser | null;
  loading: boolean;
  error: boolean;
}

const initialState: AuthState = {
  user: null,
  loading: false,
  error: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    LOGIN: (state) => {
      state.loading = true;
      state.error = false;
    },
    LOGIN_SUCCESS: (state, action: PayloadAction<IUser>) => {
      state.user = action.payload;
      state.loading = false;
    },
    LOGIN_FAILURE: (state) => {
      state.error = true;
      state.loading = false;
    },
    LOGOUT: (state) => {
      state.user = null;
    },
  },
});

export const { LOGIN, LOGIN_SUCCESS, LOGIN_FAILURE, LOGOUT } =
  authSlice.actions;

export default authSlice.reducer;
