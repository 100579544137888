import { useCallback, useState, useEffect, useRef } from 'react';
import { Add, SwapVertRounded } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Button,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';

import { MoreMenu } from 'components';
import AddUserDialog from './AddUserDialog';
import ChangePasswordDialog from './ChangePasswordDialog';
import { IUserControl } from 'interfaces';
import DeleteUserDialog from './DeleteUserDialog';
import { useAccessControl, useAuth } from 'hooks';

const AccessControl = () => {
  const nameRef = useRef<HTMLInputElement>(null);
  const { user: loggedUser } = useAuth();
  const {
    users,
    createUser,
    updateUser,
    deleteUser: deleteUserRequest,
    resetPassword,
    getUsers,
    loading,
    total,
  } = useAccessControl();
  const [page, setPage] = useState(0);
  const [name, setName] = useState('');
  const [sort, setSort] = useState('recent');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [isAddUserDialogOpen, setIsAddUserDialogOpen] = useState(false);
  const [editUser, setEditUser] = useState<IUserControl | null>(null);
  const [resetPasswordId, setResetPasswordId] = useState<string | null>(null);
  const [deleteUser, setDeleteUser] = useState<IUserControl | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onEditUser = useCallback(
    (id: string) => {
      const user = users.find((user) => user.id === id);
      setEditUser(user!);
      setIsAddUserDialogOpen(true);
    },
    [users]
  );

  useEffect(() => {
    setPage(0);
  }, [sort, name]);

  useEffect(() => {
    getUsers(page, rowsPerPage, name, sort);
  }, [getUsers, page, rowsPerPage, name, sort]);

  return (
    <Box
      sx={{
        paddingY: 4,
        paddingX: 4,
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
      }}
    >
      <Stack
        direction="row"
        sx={{ marginBottom: 2 }}
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack direction="column" spacing={1}>
          <Typography variant="h4">Controle de acesso</Typography>
          <Typography variant="body1">
            Gerencie e pesquise membros com facilidade
          </Typography>
        </Stack>
        <Button
          variant="contained"
          size="large"
          startIcon={<Add />}
          sx={{ height: 42 }}
          onClick={() => setIsAddUserDialogOpen(true)}
        >
          NOVO USUÁRIO
        </Button>
      </Stack>
      <Paper
        sx={{
          padding: 2,
          marginBottom: 2,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <Stack direction="row" width="100%">
          <TextField
            inputRef={nameRef}
            size="small"
            sx={{ flex: 1, marginRight: 2 }}
            label="Buscar"
          />
          <Button
            variant={name ? 'contained' : 'outlined'}
            size="small"
            sx={{ marginRight: 2, minWidth: 120 }}
            onClick={() => {
              if (name) {
                setName('');
                nameRef.current!.value = '';
              } else {
                setName(nameRef.current?.value || '');
              }
            }}
          >
            {name ? 'Limpar' : 'Buscar'}
          </Button>
          <Button
            id="order-button"
            variant="outlined"
            size="small"
            sx={{ marginRight: 2, minWidth: 120 }}
            aria-controls={open ? 'order-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            startIcon={<SwapVertRounded />}
          >
            Ordernar por
          </Button>
          <Menu
            id="order-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'order-button',
            }}
          >
            <MenuItem
              onClick={() => {
                setSort('recent');
                handleClose();
              }}
            >
              Recentes
            </MenuItem>
            <MenuItem
              onClick={() => {
                setSort('ascending');
                handleClose();
              }}
            >
              A-Z
            </MenuItem>
            <MenuItem
              onClick={() => {
                setSort('descending');
                handleClose();
              }}
            >
              Z-A
            </MenuItem>
          </Menu>
        </Stack>
      </Paper>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Usuários</TableCell>
              <TableCell align="left">Email</TableCell>
              <TableCell align="left">Tipo de acesso</TableCell>
              <TableCell align="right">Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRow
                key={user.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <Box flexDirection="row" display="flex" alignItems="center">
                    <Avatar
                      sx={{
                        width: 40,
                        height: 40,
                        marginRight: 1,
                      }}
                    >
                      {user.name
                        .split(' ')
                        .slice(0, 2)
                        .map((i) => i.charAt(0).toUpperCase())}
                    </Avatar>
                    {user.name}
                  </Box>
                </TableCell>
                <TableCell align="left">{user.email}</TableCell>
                <TableCell align="left">{user.role}</TableCell>
                <TableCell align="right">
                  <MoreMenu
                    disabled={user.id === loggedUser!.id}
                    onEdit={() => onEditUser(user.id)}
                    onResetPassword={() => setResetPasswordId(user.id)}
                    onDelete={() => setDeleteUser(user)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={total}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(_: unknown, newPage: number) => setPage(newPage)}
        onRowsPerPageChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setRowsPerPage(parseInt(event.target.value, 10));
          setPage(0);
        }}
      />
      <AddUserDialog
        open={isAddUserDialogOpen}
        loading={loading}
        editUser={editUser}
        onClose={() => {
          setIsAddUserDialogOpen(false);
          setEditUser(null);
        }}
        onAdd={(data) => {
          createUser(data, () => {
            setPage(0);
            setSort('recent');
            setIsAddUserDialogOpen(false);
            getUsers(0, rowsPerPage, undefined, 'recent');
          });
        }}
        onEdit={(data) => {
          updateUser(data, () => {
            getUsers(page, rowsPerPage);
            setIsAddUserDialogOpen(false);
          });
        }}
      />
      <ChangePasswordDialog
        open={!!resetPasswordId}
        onClose={() => setResetPasswordId(null)}
        onChange={() => {
          resetPassword(resetPasswordId!, () => {
            setResetPasswordId(null);
          });
        }}
      />
      <DeleteUserDialog
        user={deleteUser}
        onClose={() => setDeleteUser(null)}
        onConfirm={() => {
          deleteUserRequest(deleteUser!, () => {
            setDeleteUser(null);
            getUsers(page, rowsPerPage);
          });
        }}
      />
    </Box>
  );
};

export default AccessControl;
