import { Box, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import { useState } from 'react';

interface InputImageProps {
  url: string;
  name: string;
  onDelete?: () => void;
  maxHeight?: string;
}

const InputImage = ({
  url,
  name,
  onDelete,
  maxHeight = '150px',
}: InputImageProps) => {
  const [show, setShow] = useState(false);

  return (
    <Box
      draggable={false}
      position="relative"
      sx={{
        height: maxHeight,
        maxWidth: '300px',
        display: show ? 'block' : 'none',
        img: {
          maxWidth: '300px',
          height: maxHeight,
          width: 'auto',
          objectFit: 'cover',
          filter: 'none',
        },
      }}
    >
      {url && (
        <>
          <img
            src={url}
            alt={name}
            draggable={false}
            onLoad={() => setShow(true)}
          />
          <Box
            position="absolute"
            top={-10}
            right={-10}
            bgcolor="white"
            borderColor="primary.main"
            border="1px solid"
            borderRadius="15px"
          >
            <IconButton
              aria-label="delete"
              size="small"
              color="error"
              onClick={onDelete}
            >
              <Close fontSize="inherit" />
            </IconButton>
          </Box>
        </>
      )}
    </Box>
  );
};

export default InputImage;
