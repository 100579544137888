import { forwardRef } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
} from '@mui/material';
import ButtonLoading from '@mui/lab/LoadingButton';
import { TransitionProps } from '@mui/material/transitions';
import { IUserControl } from 'interfaces';
import { useAccessControl } from 'hooks';

type DeleteUserDialogProps = {
  user: IUserControl | null;
  onClose: () => void;
  onConfirm: () => void;
};

const Transition = forwardRef(
  (
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
  ) => <Slide direction="up" ref={ref} {...props} />
);

Transition.displayName = 'Transition';

const DeleteUserDialog = ({
  user,
  onClose,
  onConfirm,
}: DeleteUserDialogProps) => {
  const { loading } = useAccessControl();
  return (
    <Dialog
      open={!!user}
      TransitionComponent={Transition}
      fullWidth={true}
      maxWidth="xs"
      onClose={onClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>Tem certeza que deseja excluir?</DialogTitle>
      <DialogContent>
        O usuário <b>{user?.name || ''}</b> será excluido permanentemente.
      </DialogContent>
      <DialogActions sx={{ marginBottom: 3, paddingRight: 3 }}>
        <Button onClick={onClose}>Cancelar</Button>
        <ButtonLoading
          variant="contained"
          onClick={onConfirm}
          loading={loading}
        >
          Excluir
        </ButtonLoading>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteUserDialog;
