import { useCallback } from 'react';
import { TextField, Box } from '@mui/material';
import { Controller, UseFormReturn } from 'react-hook-form';
import { enqueueSnackbar } from 'notistack';
import { maskJs } from 'mask-js';
import axios from 'axios';
import { IAddressLocale } from 'interfaces';

import { BRLFormatter } from 'utils/formatters';

type AddUserDialogProps = {
  formMethods: UseFormReturn<IAddressLocale>;
};

const AddressForm = ({ formMethods }: AddUserDialogProps) => {
  const {
    control,
    formState: { errors },
    setValue,
  } = formMethods;

  const getCepInfo = useCallback(
    async (zipCode: string) => {
      const aux = zipCode.replace(/\D/g, '');
      if (aux.length !== 8) {
        return;
      }
      try {
        const { data } = await axios.get<{ [key: string]: string | null }>(
          `https://viacep.com.br/ws/${aux}/json/`
        );

        if (!data.error) {
          setValue('addressLine', data?.logradouro || '');
          setValue('neighborhood', data?.bairro || '');
          setValue('state', data?.uf || '');
          setValue('city', data?.localidade || '');
        }
      } catch {
        enqueueSnackbar('Erro ao buscar CEP', { variant: 'warning' });
      }
    },
    [setValue]
  );

  return (
    <Box>
      <Controller
        name="name"
        control={control}
        render={({ field }) => {
          return (
            <TextField
              sx={{ width: '100%', marginTop: 2 }}
              label="Nome"
              {...field}
              value={field.value || ''}
              onChange={(e) => field.onChange(e.target.value)}
              error={!!errors.name}
              helperText={errors.name?.message}
            />
          );
        }}
      />
      <Box display="flex" flexDirection="row" width="100%">
        <Controller
          name="zipCode"
          control={control}
          render={({ field }) => {
            return (
              <TextField
                sx={{ flex: 1, marginTop: 2, marginRight: 2 }}
                label="CEP"
                {...field}
                error={!!errors.zipCode}
                helperText={errors.zipCode?.message}
                value={maskJs('99999-999', field.value || '')}
                onChange={(e) => {
                  const { value } = e.target;
                  field.onChange(value);
                  getCepInfo(value);
                }}
              />
            );
          }}
        />
        <Controller
          name="addressLine"
          control={control}
          render={({ field }) => {
            return (
              <TextField
                sx={{ flex: 3, marginTop: 2, marginRight: 2 }}
                label="Rua"
                {...field}
                value={field.value || ''}
                onChange={(e) => field.onChange(e.target.value)}
                error={!!errors.addressLine}
                helperText={errors.addressLine?.message}
              />
            );
          }}
        />
        <Controller
          name="number"
          control={control}
          render={({ field }) => {
            return (
              <TextField
                sx={{ flex: 1, marginTop: 2, marginRight: 2 }}
                label="Número"
                {...field}
                value={maskJs('9999999', field.value || '')}
                onChange={(e) => field.onChange(e.target.value)}
                error={!!errors.number}
                helperText={errors.number?.message}
              />
            );
          }}
        />
        <Controller
          name="complement"
          control={control}
          render={({ field }) => {
            return (
              <TextField
                sx={{ flex: 1, marginTop: 2 }}
                label="Complemento"
                {...field}
                value={field.value || ''}
                onChange={(e) => field.onChange(e.target.value)}
                error={!!errors.complement}
                helperText={errors.complement?.message}
              />
            );
          }}
        />
      </Box>
      <Box display="flex" flexDirection="row" width="100%">
        <Controller
          name="state"
          control={control}
          render={({ field }) => {
            return (
              <TextField
                sx={{ flex: 1, marginTop: 2, marginRight: 2 }}
                label="UF"
                {...field}
                value={field.value || ''}
                onChange={(e) => field.onChange(e.target.value)}
                error={!!errors.state}
                helperText={errors.state?.message}
              />
            );
          }}
        />
        <Controller
          name="city"
          control={control}
          render={({ field }) => {
            return (
              <TextField
                sx={{ flex: 3, marginTop: 2, marginRight: 2 }}
                label="Cidade"
                {...field}
                value={field.value || ''}
                onChange={(e) => field.onChange(e.target.value)}
                error={!!errors.city}
                helperText={errors.city?.message}
              />
            );
          }}
        />
        <Controller
          name="price"
          control={control}
          render={({ field }) => {
            return (
              <TextField
                sx={{ flex: 2, marginTop: 2 }}
                label="Valor da locação"
                {...field}
                value={BRLFormatter.format((field.value || 0) / 100)}
                onChange={(e) =>
                  field.onChange(parseInt(e.target.value.replace(/\D/g, '')))
                }
                error={!!errors.price}
                helperText={errors.price?.message}
              />
            );
          }}
        />
      </Box>
      <Controller
        name="observations"
        control={control}
        render={({ field }) => {
          return (
            <TextField
              sx={{ width: '100%', marginTop: 2 }}
              label="Observações"
              {...field}
              value={field.value || ''}
              onChange={(e) => field.onChange(e.target.value)}
              error={!!errors.observations}
              helperText={errors.observations?.message}
              multiline
              rows={4}
            />
          );
        }}
      />
    </Box>
  );
};

export default AddressForm;
