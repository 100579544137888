import Router from 'router';
import ThemeProvider from 'theme';
import { SnackbarProvider } from 'notistack';

import Store from 'store';

const App = () => {
  return (
    <Store>
      <ThemeProvider>
        <SnackbarProvider>
          <Router />
        </SnackbarProvider>
      </ThemeProvider>
    </Store>
  );
};

export default App;
