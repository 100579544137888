import { useCallback } from 'react';
import dayjs from 'dayjs';
import { enqueueSnackbar } from 'notistack';
import { api, setToken } from 'api';
import { IAuth, LoginFunc } from 'interfaces';
import { LOGIN, LOGIN_FAILURE, LOGIN_SUCCESS, LOGOUT } from 'store/auth.slice';

import { useAppDispatch, useAppSelector } from 'store';

const useAuth = () => {
  const dispatch = useAppDispatch();
  const { user, loading, error } = useAppSelector(({ auth }) => auth);

  const updateRefreshToken = useCallback(() => {
    const { token, expiration } = JSON.parse(
      localStorage.getItem('auth') || '{}'
    );

    if (!token) {
      return;
    }

    const now = dayjs();
    const targetDate = dayjs().add(expiration);

    const diff = targetDate.diff(now, 'hour');

    if (diff <= 1) {
      setToken(token);
      api
        .post<IAuth>('/user/refresh-token')
        .then(({ data }) => {
          localStorage.setItem(
            'auth',
            JSON.stringify({
              token: data.token,
              expiresAt: dayjs().add(1, 'day').toISOString(),
            })
          );
          setToken(data.token);
        })
        .catch(() => {
          dispatch(LOGOUT());
        });
    }
  }, [dispatch]);

  const login = useCallback(
    async (
      { email, password, saveInfo }: LoginFunc,
      successCalback: () => void
    ) => {
      dispatch(LOGIN());

      try {
        const { data } = await api.post<IAuth>('/user/login', {
          email,
          password,
        });

        const { token } = data;

        setToken(token);

        if (saveInfo) {
          localStorage.setItem(
            'auth',
            JSON.stringify({
              token,
              expiration: dayjs().add(3, 'day').toISOString(),
            })
          );
        }

        const { data: apiUser } = await api.get('/user/profile');
        if (!apiUser.firstAccess) {
          successCalback();
        }
        dispatch(LOGIN_SUCCESS(apiUser));
      } catch (e) {
        dispatch(LOGIN_FAILURE());
        enqueueSnackbar('Usuário ou senha inválidos', {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    },
    [dispatch]
  );

  const firstAccess = useCallback(
    async (newPassword: string, successCalback: () => void) => {
      try {
        if (!user) {
          throw new Error('Usuário não encontrado');
        }
        await api.post('/user/first-access', {
          newPassword,
        });
        dispatch(LOGIN_SUCCESS({ ...user, firstAccess: false }));
        successCalback();
      } catch (e) {
        dispatch(LOGIN_FAILURE());
        enqueueSnackbar('Ops... Um erro aconteceu', {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    },
    [user, dispatch]
  );

  const logout = useCallback(async () => {
    try {
      setToken('');
      localStorage.removeItem('auth');
      dispatch(LOGOUT());
    } catch (e) {
      dispatch(LOGIN_FAILURE());
    }
  }, [dispatch]);

  return {
    login,
    user,
    loading,
    error,
    firstAccess,
    updateRefreshToken,
    logout,
  };
};

export default useAuth;
