import { useCallback } from 'react';
import { IGetUser, IUserControl, IAddUser } from 'interfaces';
import { useAppDispatch, useAppSelector } from 'store';
import { enqueueSnackbar } from 'notistack';
import {
  DELETE_USER,
  UPDATE_USER,
  SET_USERS,
  SET_USER_LOADING,
} from 'store/accessControl.slice';

import { api } from 'api';

const useAccessControl = () => {
  const dispatch = useAppDispatch();
  const { users, loading, lastPage, total } = useAppSelector(
    ({ accessControl }) => accessControl
  );

  const createUser = useCallback(
    async (user: IAddUser, onSuccess?: () => void) => {
      try {
        dispatch(SET_USER_LOADING(true));
        await api.post<IUserControl>('/user/register', user);
        dispatch(SET_USER_LOADING(false));
        onSuccess?.();
      } catch (error) {
        dispatch(SET_USER_LOADING(false));
        enqueueSnackbar('Erro criando usuário', {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    },
    [dispatch]
  );

  const resetPassword = useCallback(
    async (userId: string, onSuccess?: () => void) => {
      try {
        dispatch(SET_USER_LOADING(true));
        await api.post<IUserControl>(`/user/${userId}/reset-password`);
        onSuccess?.();
        dispatch(SET_USER_LOADING(false));
      } catch (error) {
        dispatch(SET_USER_LOADING(false));
        enqueueSnackbar('Erro ao resetar senha', {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    },
    [dispatch]
  );

  const searchUser = useCallback(
    async (name: string) => {
      const result = await api.get<IGetUser>(`/user/${name}`);
      dispatch(SET_USERS(result.data));
    },
    [dispatch]
  );

  const deleteUser = useCallback(
    async (user: IUserControl, onSuccess?: () => void) => {
      try {
        dispatch(SET_USER_LOADING(true));
        await api.delete<IUserControl>(`/user/${user.id}/delete`);
        onSuccess?.();
        dispatch(DELETE_USER(user));
      } catch (error) {
        dispatch(SET_USER_LOADING(false));
        enqueueSnackbar('Erro ao deletar usuário', {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    },
    [dispatch]
  );

  const updateUser = useCallback(
    async (user: IUserControl, onSuccess?: () => void) => {
      try {
        dispatch(SET_USER_LOADING(true));
        await api.patch<IUserControl>(`/user/${user.id}/update`, {
          email: user.email,
          name: user.name,
          role: user.role,
        });
        onSuccess?.();
        dispatch(UPDATE_USER(user));
      } catch (error) {
        dispatch(SET_USER_LOADING(false));
        enqueueSnackbar('Erro ao atualizar usuário', {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    },
    [dispatch]
  );

  const getUsers = useCallback(
    async (page?: number, limit?: number, name?: string, sort?: string) => {
      try {
        SET_USER_LOADING(true);
        const { data } = await api.get<IGetUser>('/user', {
          params: { page: (page || 0) + 1, limit, name, sort },
        });
        dispatch(SET_USERS(data));
      } catch (error) {
        dispatch(SET_USER_LOADING(false));
        enqueueSnackbar('Erro ao buscar usuários', {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    },
    [dispatch]
  );

  return {
    users,
    lastPage,
    total,
    loading,
    getUsers,
    createUser,
    deleteUser,
    updateUser,
    searchUser,
    resetPassword,
  };
};

export default useAccessControl;
