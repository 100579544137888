import { createSlice } from '@reduxjs/toolkit';
import { UploadFolder } from 'interfaces';
interface UploadState {
  open: boolean;
  folders: UploadFolder[];
}

const initialState: UploadState = {
  open: false,
  folders: [],
};

export const uploadSlice = createSlice({
  name: 'upload',
  initialState,
  reducers: {
    INIT_UPLOAD: (state, { payload }: { payload: UploadFolder }) => {
      const folder = state.folders.find(
        (folder) => folder.folderCode === payload.folderCode
      );

      if (folder) {
        folder.images = folder.images.concat(payload.images);
      } else {
        state.folders = state.folders.concat(payload);
      }
      state.open = true;
    },
    UPLOAD_STARTED: (
      state,
      { payload }: { payload: { fileName: string; folderCode: string } }
    ) => {
      const folder = state.folders.find(
        (folder) => folder.folderCode === payload.folderCode
      );

      const image = folder!.images.find(
        (image) => image.name === payload.fileName
      );

      image!.loading = true;
    },
    UPLOAD_SUCCESS: (
      state,
      { payload }: { payload: { fileName: string; folderCode: string } }
    ) => {
      const folder = state.folders.find(
        (folder) => folder.folderCode === payload.folderCode
      );

      const image = folder!.images.find(
        (image) => image.name === payload.fileName
      );

      image!.uploaded = true;
      image!.loading = false;
    },
    UPLOAD_ERROR: (
      state,
      { payload }: { payload: { fileName: string; folderCode: string } }
    ) => {
      const folder = state.folders.find(
        (folder) => folder.folderCode === payload.folderCode
      );

      const image = folder!.images.find(
        (image) => image.name === payload.fileName
      );

      image!.error = true;
      image!.loading = false;
    },
    UPDATE_PROGRESS: (
      state,
      {
        payload,
      }: { payload: { fileName: string; folderCode: string; progress: number } }
    ) => {
      const folder = state.folders.find(
        (folder) => folder.folderCode === payload.folderCode
      );

      const image = folder!.images.find(
        (image) => image.name === payload.fileName
      );

      image!.progress = payload.progress;
    },
    CLOSE_UPLOAD: (state) => {
      state.open = false;
    },
  },
});

export const {
  INIT_UPLOAD,
  UPLOAD_STARTED,
  UPLOAD_SUCCESS,
  UPLOAD_ERROR,
  UPDATE_PROGRESS,
  CLOSE_UPLOAD,
} = uploadSlice.actions;

export default uploadSlice.reducer;
