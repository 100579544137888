import { createSlice } from '@reduxjs/toolkit';

interface DrawerState {
  open: boolean;
}

const initialState: DrawerState = {
  open: true,
};

export const drawerSlice = createSlice({
  name: 'drawer',
  initialState,
  reducers: {
    SET_DRAWER_OPEN: (state, { payload }: { payload: boolean }) => {
      if (window.location.pathname === '/biblioteca') {
        state.open = false;
      } else {
        state.open = payload;
      }
    },
    TOOGLE_DRAWER: (state) => {
      if (window.location.pathname === '/biblioteca') {
        state.open = false;
      } else {
        state.open = !state.open;
      }
    },
  },
});

export const { SET_DRAWER_OPEN, TOOGLE_DRAWER } = drawerSlice.actions;

export default drawerSlice.reducer;
