import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { SET_DRAWER_OPEN, TOOGLE_DRAWER } from 'store/drawer.slice';

const useDrawer = () => {
  const dispatch = useAppDispatch();
  const { open } = useAppSelector(({ drawer }) => drawer);

  const toggleDrawer = useCallback(() => {
    dispatch(TOOGLE_DRAWER());
  }, [dispatch]);

  const setDrawerOpen = useCallback(
    (isOpen: boolean) => {
      dispatch(SET_DRAWER_OPEN(isOpen));
    },
    [dispatch]
  );

  return { open, toggleDrawer, setDrawerOpen };
};

export default useDrawer;
