import { useRef, useState } from 'react';
import { MoreVert } from '@mui/icons-material';
import { IconButton, Menu, MenuItem } from '@mui/material';

type MoreMenuProps = {
  onEdit: () => void;
  onResetPassword?: () => void;
  onDelete: () => void;
  disabled?: boolean;
};

const MoreMenu = ({
  onEdit,
  onResetPassword,
  onDelete,
  disabled,
}: MoreMenuProps) => {
  const [isOpen, setOpen] = useState(false);
  const ref = useRef(null);
  return (
    <div>
      <IconButton aria-label="Example" onClick={() => setOpen(true)} ref={ref}>
        <MoreVert />
      </IconButton>
      <Menu
        open={isOpen}
        onClose={() => setOpen(false)}
        anchorEl={ref.current}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={onEdit}>Editar</MenuItem>
        {onResetPassword && (
          <MenuItem onClick={onResetPassword}>Trocar senha</MenuItem>
        )}
        {!disabled && <MenuItem onClick={onDelete}>Excluir</MenuItem>}
      </Menu>
    </div>
  );
};

export default MoreMenu;
