import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  PlaceOutlined,
  CameraAltOutlined,
  Check,
  ChevronLeft,
  ChevronRight,
  AddRounded,
  FileDownloadOutlined,
  ZoomIn,
  ZoomOut,
  EditRounded,
} from '@mui/icons-material';
import {
  Box,
  Typography,
  Paper,
  Slider,
  Stack,
  Chip,
  Divider,
  Button,
  CircularProgress,
} from '@mui/material';

import { FileUpload, LibraryCard } from 'components';
import DragSelect, { DSInputElement } from 'dragselect';
import { useDrawer, useLocales, useUpload } from 'hooks';
import { LocaleList } from 'components';

import AddLocaleDialog from './AddLocaleDialog';
import { FileWithUrl } from 'components/FileUpload/FileUpload';
const Library = () => {
  const ds = useRef<DragSelect | null>(null);
  const { localeInfo, loadingInfo, downloadImages, getLocaleById, allLocales } =
    useLocales();
  const { images, uploaded } = useUpload();
  const { setDrawerOpen } = useDrawer();
  const [isAddLocaleOpen, setAddLocaleOpen] = useState(false);
  const [isEditLocale, setEditLocale] = useState(false);
  const [selectedImages, setSelectedImages] = useState<string[]>([]);
  const [showUpload, setShowUpload] = useState(false);
  const [inputFiles, setInputFiles] = useState<FileWithUrl[]>([]);

  const [imgPerRow, setImgPerRow] = useState(4);

  useEffect(() => {
    setDrawerOpen(false);
  }, [setDrawerOpen]);

  const imgHeight = useMemo(() => {
    return `calc(800px / ${imgPerRow})`;
  }, [imgPerRow]);

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (localeInfo && !isAddLocaleOpen) {
      setShowUpload(true);
    }
  };

  const clickNext = useCallback(() => {
    const currentIndex = allLocales.findIndex(
      (loc) => loc.id === localeInfo?.id
    );
    if (currentIndex > -1 && currentIndex < allLocales.length - 1) {
      const nextLocale = allLocales[currentIndex + 1];
      getLocaleById(nextLocale.id);
    }
  }, [allLocales, getLocaleById, localeInfo]);

  const clickPrevious = useCallback(() => {
    const currentIndex = allLocales.findIndex(
      (loc) => loc.id === localeInfo?.id
    );
    if (currentIndex > 0) {
      const nextLocale = allLocales[currentIndex - 1];
      getLocaleById(nextLocale.id);
    }
  }, [allLocales, getLocaleById, localeInfo]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      switch (event.key) {
        case 'ArrowUp':
          event.preventDefault();
          clickPrevious();
          break;
        case 'ArrowDown':
          event.preventDefault();
          clickNext();
          break;
        default:
          break;
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [clickNext, clickPrevious]);

  useEffect(() => {
    setInputFiles([]);
    setSelectedImages([]);
    setShowUpload(false);
    if (localeInfo) {
      ds.current = new DragSelect({
        selectables: document.getElementsByClassName(
          'selectable-nodes'
        ) as unknown as DSInputElement[],
        area: document.getElementById('selectable-area') as HTMLElement,
        draggability: false,
        multiSelectToggling: true,
      });

      ds.current.subscribe('DS:start:pre', () => {
        if (window.getSelection) {
          if (window.getSelection()?.empty) {
            window.getSelection()?.empty();
          } else if (window.getSelection()?.removeAllRanges) {
            window.getSelection()?.removeAllRanges();
          }
        }
      });

      ds.current.subscribe('DS:end', ({ items }) => {
        setSelectedImages(items.map((item) => item.id));
      });
    }

    return () => {
      ds.current?.stop();
    };
  }, [localeInfo]);

  useEffect(() => {
    ds.current?.addSelectables(
      document.getElementsByClassName(
        'selectable-nodes'
      ) as unknown as DSInputElement[]
    );
  }, [uploaded]);

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    // Check if the relatedTarget is outside the current element
    if (!event.currentTarget.contains(event.relatedTarget as Node)) {
      setShowUpload(false);
    }
  };

  return (
    <Stack
      direction="row"
      height="100%"
      minHeight="100vh"
      width="100%"
      position="relative"
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
    >
      <LocaleList />
      <Box
        sx={{
          paddingX: 4,
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
        }}
      >
        <Stack direction="row" justifyContent="space-between" paddingTop={4}>
          <Typography variant="h4">Biblioteca</Typography>
          <Stack direction="row">
            <Button
              variant="contained"
              startIcon={<AddRounded />}
              onClick={() => setAddLocaleOpen(true)}
            >
              Novo Local
            </Button>
            {localeInfo && (
              <Button
                variant="outlined"
                sx={{ marginLeft: 2 }}
                startIcon={<EditRounded />}
                onClick={() => {
                  setAddLocaleOpen(true);
                  setEditLocale(true);
                }}
              >
                Editar
              </Button>
            )}
          </Stack>
        </Stack>
        {localeInfo ? (
          <Paper
            sx={{
              borderRadius: 2,
              padding: 0,
              marginTop: 2,
              marginBottom: 2,
              display: 'flex',
              flexDirection: 'column',
              height: 'calc(100% - 64px)',
            }}
          >
            <Stack direction="column" padding={3}>
              <Stack direction="row" justifyContent="space-between">
                <Stack direction="column">
                  <Typography variant="h6" maxWidth="720px" whiteSpace="wrap">
                    {localeInfo.name}
                  </Typography>
                  <Typography variant="caption">{localeInfo.code}</Typography>
                </Stack>
                <Stack direction="row" alignItems="center">
                  <Button
                    variant="contained"
                    endIcon={<FileDownloadOutlined />}
                    sx={{ height: 40, mr: 1 }}
                    onClick={() => {
                      downloadImages(localeInfo.images.map((img) => img.id));
                    }}
                  >
                    Baixar todas
                  </Button>
                  <Button
                    variant="text"
                    sx={{ marginRight: 1, color: 'text.primary' }}
                    startIcon={<ChevronLeft />}
                    onClick={clickPrevious}
                  >
                    Anterior
                  </Button>
                  <Button
                    variant="text"
                    endIcon={<ChevronRight />}
                    sx={{ color: 'text.primary' }}
                    onClick={clickNext}
                  >
                    Próxima
                  </Button>
                </Stack>
              </Stack>
              <Divider sx={{ mb: 3, mt: 1 }} />
              <Typography variant="subtitle1">
                {localeInfo.address
                  ? `${localeInfo.address.city} - ${localeInfo.address.state}`
                  : 'Cidade não cadastrada'}
              </Typography>
              <Stack
                direction="row"
                alignItems="center"
                marginTop={0.5}
                marginBottom={0.5}
              >
                <PlaceOutlined
                  color="disabled"
                  sx={{ color: 'action.active', mr: 1 }}
                />
                <Typography
                  variant="body2"
                  color="action.active"
                  marginRight={2}
                >
                  {localeInfo.address
                    ? localeInfo.address.addressLine
                    : 'Endereço não cadastrado'}
                </Typography>
                <CameraAltOutlined sx={{ color: 'action.active', mr: 1 }} />
                <Typography
                  variant="body2"
                  color="action.active"
                  marginLeft={0.5}
                >
                  {localeInfo?.images.length} fotos
                </Typography>
              </Stack>

              <Typography variant="body2" color="action.active" mt={1}>
                <b>Obs: </b>{' '}
                {localeInfo.observations
                  ? localeInfo.observations
                  : 'Nenhuma observação'}
              </Typography>
              <Stack direction="row" marginTop={1}>
                {localeInfo.categories.map((cat) => (
                  <Chip key={cat.id} label={cat.name} sx={{ marginRight: 1 }} />
                ))}
              </Stack>
            </Stack>
            {
              <FileUpload
                show={showUpload}
                onClose={() => setShowUpload(false)}
                inputFiles={inputFiles}
                setInputFiles={setInputFiles}
              />
            }
            <Stack
              display={showUpload ? 'none' : 'flex'}
              id="selectable-area"
              paddingLeft={3}
              paddingRight={3}
              paddingBottom={12}
              direction="row"
              flexWrap="wrap"
              justifyContent="center"
              rowGap={3}
              columnGap={1}
              marginTop={2}
              sx={{ userSelect: 'none' }}
            >
              {localeInfo?.images.length || images.length ? (
                <>
                  {images.map((img) => (
                    <LibraryCard
                      key={img.name}
                      id={img.name}
                      name={img.name}
                      code={'ELOCS' + localeInfo.code + img.code}
                      url={img.url}
                      maxHeight={imgHeight}
                      isLoading={img.loading}
                      isUploadMode={!img.uploaded}
                      progress={img.progress}
                    />
                  ))}
                  {localeInfo?.images.map((img) => (
                    <LibraryCard
                      key={img.id}
                      id={img.id}
                      code={'ELOCS' + localeInfo.code + img.code}
                      name={img.name}
                      url={img.thumbnail}
                      maxHeight={imgHeight}
                    />
                  ))}
                </>
              ) : (
                <Box
                  display="flex"
                  width="100%"
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography variant="body1" color="textSecondary">
                    Nenhuma imagem encontrada
                  </Typography>
                </Box>
              )}
            </Stack>
          </Paper>
        ) : (
          <Stack
            direction="column"
            alignItems="center"
            justifyContent="center"
            flex={1}
          >
            {loadingInfo ? (
              <CircularProgress />
            ) : (
              <Typography variant="body1">
                Selecione um local para visualizar e fazer modificações
              </Typography>
            )}
          </Stack>
        )}

        <AddLocaleDialog
          isOpen={isAddLocaleOpen}
          isEdit={isEditLocale}
          onClose={() => {
            setAddLocaleOpen(false);
            setEditLocale(false);
          }}
          onFinish={(loc) => getLocaleById(loc.id)}
        />
      </Box>
      {localeInfo && (
        <Stack
          direction="row"
          sx={{
            zIndex: 5,
            maxWidth: 'calc(100% - 418px)',
            position: 'fixed',
            bottom: 20,
            left: 386,
            paddingRight: 4,
          }}
        >
          <Paper
            sx={{
              borderRadius: 2,
              paddingX: 2,
              flex: 1,
              display: 'flex',
              direction: 'row',
              alignContent: 'center',
              justifyContent: 'space-between',
              boxShadow: 24,
            }}
          >
            <Stack direction="row" alignItems="center">
              <ZoomIn />
              <Slider
                sx={{ width: 160, color: 'common.black', ml: 2, mr: 1 }}
                aria-label="images-per-row"
                defaultValue={4}
                value={imgPerRow}
                onChange={(e, value) => setImgPerRow(value as number)}
                step={2}
                min={4}
                max={12}
              />
              <ZoomOut />
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              padding={2}
              justifyContent="space-between"
            >
              <Stack direction="row" alignItems="center">
                <Typography
                  variant="body1"
                  fontWeight={500}
                  color="common.black"
                  mr={1}
                  whiteSpace="nowrap"
                  overflow="hidden"
                  maxWidth={300}
                  textOverflow="ellipsis"
                >
                  {localeInfo.name}
                </Typography>
                <Typography
                  display="flex"
                  flex={1}
                  variant="body1"
                  fontWeight={500}
                  color="common.black"
                  mr={4}
                >
                  - {localeInfo.code}
                </Typography>
                <Check />
                <Typography variant="body2" color="common.black" mr={4}>
                  {selectedImages.length} fotos selecionadas
                </Typography>
                <Button
                  variant="contained"
                  endIcon={<FileDownloadOutlined />}
                  onClick={() => {
                    downloadImages(selectedImages);
                  }}
                >
                  Download
                </Button>
              </Stack>
            </Stack>
          </Paper>
        </Stack>
      )}
    </Stack>
  );
};

export default Library;
