import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Chip,
  List,
  ListItemButton,
  ListItemText,
  OutlinedInput,
  Stack,
  Typography,
} from '@mui/material';

import {
  Search,
  // FilterListRounded,
  // CameraAltOutlined,
} from '@mui/icons-material';
import { ICategory } from 'interfaces';
import { useCategories, useLocales } from 'hooks';
import { useAppDispatch } from 'store';
import { CLEAR_SEARCH_LOCALES } from 'store/locales.slice';

const SearchLocale = () => {
  const dispatch = useAppDispatch();
  const [text, setText] = useState('');
  const [isFocused, setFocus] = useState(false);
  const [categories, setCategories] = useState<ICategory[]>([]);
  const [fetchCategories, setFetchCategories] = useState<ICategory[]>([]);
  const [recentCategories, setRecentCategories] = useState<ICategory[]>([]);

  const { getByName } = useCategories();

  const { getByName: localeByName } = useLocales();

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (!document.getElementById('search-area')?.contains(e.target as Node)) {
        setFocus(false);
      }
    };

    window.addEventListener('click', handleClick);

    return () => {
      window.removeEventListener('click', handleClick);
    };
  }, []);

  useEffect(() => {
    const recent = JSON.parse(
      localStorage.getItem('recent-categories') || '[]'
    );

    setRecentCategories(
      recent.filter(
        (cat: ICategory) => !categories.some((c) => c.id === cat.id)
      )
    );
  }, [isFocused, categories]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      switch (event.key) {
        case 'Enter':
          event.preventDefault();
          localeByName(text, categories);
          break;
        default:
          break;
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [text, categories, localeByName]);

  return (
    <div id="search-area" style={{ marginTop: '8px', marginBottom: '16px' }}>
      <OutlinedInput
        sx={{
          paddingX: 1,
          borderRadius: 2,
          input: { paddingY: 1.5 },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline, &:hover > .MuiOutlinedInput-notchedOutline':
            {
              borderColor: 'action.active',
              borderWidth: 1,
            },
          ...((isFocused || !!categories.length) && {
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: 'action.active',
            },
          }),
        }}
        onFocus={() => setFocus(true)}
        // onBlur={() => setFocus(false)}
        fullWidth
        onChange={(e) => {
          setText(e.target.value);
          if (e.target.value.length === 0) {
            setCategories([]);
            setFetchCategories([]);
            dispatch(CLEAR_SEARCH_LOCALES());
          }
          if (e.target.value.length > 2) {
            getByName(e.target.value, (data: ICategory[]) =>
              setFetchCategories(
                data.filter((cat) => !categories.some((c) => c.id === cat.id))
              )
            );
          } else {
            setFetchCategories([]);
          }
        }}
        value={text}
        placeholder="Buscar"
        color="success"
        startAdornment={<Search sx={{ color: 'action.active', mr: 1 }} />}
        // endAdornment={
        //   <IconButton sx={{ color: 'action.active' }}>
        //     <FilterListRounded />
        //   </IconButton>
        // }
      />
      {(isFocused || !!categories.length) && !fetchCategories.length && (
        <Box
          border="1px solid"
          borderColor="action.active"
          mt={1}
          borderRadius={2}
          padding={2}
          pt={0}
        >
          {!!categories.length && (
            <>
              <Typography variant="overline" color="text.secondary">
                CATEGORIAS
              </Typography>
              <Stack
                direction="row"
                flexWrap="wrap"
                rowGap={1}
                columnGap={1}
                mb={1}
              >
                {categories.map((cat) => (
                  <Chip
                    key={cat.id}
                    label={cat.name}
                    variant="outlined"
                    size="small"
                    onDelete={() =>
                      setCategories((prev) =>
                        prev.filter((c) => c.id !== cat.id)
                      )
                    }
                  />
                ))}
              </Stack>
            </>
          )}
          <Typography variant="overline" color="text.secondary">
            RECENTES
          </Typography>
          {/* <Stack direction="row" alignItems="center">
            <CameraAltOutlined sx={{ mr: 1 }} />
            <Typography variant="caption" color="text.secondary">
              Fotógrafo
            </Typography>
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            mt={1}
            flexWrap="wrap"
            rowGap={1}
            columnGap={1}
          >
            <Chip label="Guilherme" variant="outlined" size="small" />
            <Chip label="Marcelo" variant="outlined" size="small" />
          </Stack> */}
          <Stack
            direction="row"
            alignItems="center"
            flexWrap="wrap"
            rowGap={1}
            columnGap={1}
          >
            <Search sx={{ color: 'action.active' }} />
            <Typography variant="caption" color="text.secondary">
              Categorias
            </Typography>
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            mt={1}
            flexWrap="wrap"
            rowGap={1}
            columnGap={1}
          >
            {recentCategories.length ? (
              recentCategories.map((cat) => (
                <Chip
                  key={cat.id}
                  label={cat.name}
                  variant="outlined"
                  size="small"
                  onClick={() => {
                    setCategories((prev) => prev.concat(cat));
                  }}
                />
              ))
            ) : (
              <Typography variant="caption">Sem categorias recentes</Typography>
            )}
          </Stack>
          {(!!categories.length || text) && (
            <Stack
              direction="row"
              alignItems="center"
              mt={2}
              justifyContent="flex-end"
            >
              <Button
                sx={{ mr: 1 }}
                variant="outlined"
                size="small"
                onClick={() => {
                  setCategories([]);
                  setFetchCategories([]);
                  setText('');
                  dispatch(CLEAR_SEARCH_LOCALES());
                  setTimeout(() => {
                    document.getElementById('scrollableDiv')?.scrollTo({
                      top: 0,
                    });
                  }, 100);
                }}
              >
                Limpar
              </Button>
              <Button
                variant="contained"
                size="small"
                onClick={() => localeByName(text, categories)}
              >
                Buscar
              </Button>
            </Stack>
          )}
        </Box>
      )}
      {!!fetchCategories.length && (
        <Box
          border="1px solid"
          borderColor="action.active"
          mt={1}
          borderRadius={2}
          pt={0}
        >
          {!!fetchCategories.length && (
            <>
              <Typography
                variant="overline"
                color="text.secondary"
                padding={2}
                pb={0}
              >
                SUGESTÕES
              </Typography>
              <List dense sx={{ p: 0 }}>
                {fetchCategories.map((cat) => (
                  <ListItemButton
                    key={cat.id}
                    onClick={() => {
                      setCategories((prev) => prev.concat(cat));
                      setText('');
                      setFetchCategories([]);
                    }}
                  >
                    <ListItemText primary={cat.name} />
                  </ListItemButton>
                ))}
              </List>
            </>
          )}
        </Box>
      )}
    </div>
  );
};

export default SearchLocale;
