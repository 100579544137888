import { useEffect } from 'react';
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from 'react-router-dom';

import Login from 'pages/Login';
import { useAuth } from 'hooks';

import Home from 'pages/Home';
import AccessControl from 'pages/AccessControl';
import Library from 'pages/Library';
import Categories from 'pages/Categories';

import ProtectedRoute from './ProtectedRoute';

const router = createBrowserRouter([
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/',
    element: <ProtectedRoute component={<Home />} />,

    children: [
      {
        path: 'biblioteca',
        element: <Library />,
      },
      {
        path: 'categorias',
        element: <Categories />,
      },
      {
        path: 'controle-acesso',
        element: <AccessControl />,
      },
    ],
  },
  {
    path: '*',
    element: <Navigate to="/error" replace />,
  },
]);

const Router = () => {
  const { updateRefreshToken } = useAuth();

  useEffect(() => {
    updateRefreshToken();
  }, [updateRefreshToken]);

  return <RouterProvider router={router} />;
};

export default Router;
