import {
  Box,
  Drawer as MuiDrawer,
  MenuList,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Chip,
  styled,
  Theme,
  CSSObject,
  IconButton,
  Stack,
} from '@mui/material';
import {
  SpaceDashboard,
  Folder,
  Photo,
  People,
  Notifications,
  Settings,
  ExitToApp,
  KeyboardDoubleArrowLeft as DoubleArrow,
  Menu,
  CategoryRounded,
  AccountBalanceRounded,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'hooks';
import { useState } from 'react';

const drawerWidth = 336;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const DrawerMenu = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: () => void;
}) => {
  const navigate = useNavigate();
  const { logout } = useAuth();

  const [selected, setSelected] = useState('library');

  return (
    <Drawer open={open} variant="permanent" anchor="left">
      <Box display="flex" flexDirection="column" flex={1} bgcolor="grey.300">
        <MenuList
          sx={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            '& li': {
              paddingY: 1.3,
              ...(!open && { paddingLeft: '20px' }),
            },
            ...(!open && {
              '& span': {
                visibility: 'hidden',
              },
              '& .MuiChip-root': {
                visibility: 'hidden',
              },
            }),
          }}
        >
          <Stack direction="row" justifyContent={open ? 'flex-end' : 'center'}>
            <IconButton
              sx={{
                backgroundColor: 'grey.300',
                ':hover': {
                  backgroundColor: 'grey.300',
                },
              }}
              onClick={() => setOpen()}
            >
              {!open ? (
                <Menu sx={{ color: 'grey.800' }} />
              ) : (
                <DoubleArrow sx={{ color: 'grey.800' }} />
              )}
            </IconButton>
          </Stack>
          <MenuItem sx={{ paddingLeft: 4 }} selected={selected === 'dashboard'}>
            <ListItemIcon>
              <SpaceDashboard sx={{ color: 'grey.800' }} />
            </ListItemIcon>
            <ListItemText sx={{ color: 'grey.800' }}>Dashboard</ListItemText>
            <Chip label="Em breve" sx={{ color: 'grey.800' }} size="small" />
          </MenuItem>
          <MenuItem sx={{ paddingLeft: 4 }} selected={selected === 'projects'}>
            <ListItemIcon>
              <Folder sx={{ color: 'grey.800' }} />
            </ListItemIcon>
            <ListItemText sx={{ color: 'grey.800' }}>Projetos</ListItemText>
            <Chip label="Em breve" sx={{ color: 'grey.800' }} size="small" />
          </MenuItem>
          <MenuItem sx={{ paddingLeft: 4 }} selected={selected === 'financial'}>
            <ListItemIcon>
              <AccountBalanceRounded sx={{ color: 'grey.800' }} />
            </ListItemIcon>
            <ListItemText sx={{ color: 'grey.800' }}>Financeiro</ListItemText>
            <Chip label="Em breve" sx={{ color: 'grey.800' }} size="small" />
          </MenuItem>
          <MenuItem
            sx={{ paddingLeft: 4 }}
            onClick={() => {
              setSelected('catgegory');
              navigate('/categorias');
            }}
            selected={selected === 'catgegory'}
          >
            <ListItemIcon>
              <CategoryRounded sx={{ color: 'grey.800' }} />
            </ListItemIcon>
            <ListItemText sx={{ color: 'grey.800' }}>Categorias</ListItemText>
          </MenuItem>
          <MenuItem
            sx={{ paddingLeft: 4 }}
            onClick={() => {
              setSelected('library');
              navigate('/biblioteca');
            }}
            selected={selected === 'library'}
          >
            <ListItemIcon>
              <Photo sx={{ color: 'grey.800' }} />
            </ListItemIcon>
            <ListItemText sx={{ color: 'grey.800' }}>Biblioteca</ListItemText>
          </MenuItem>
          <MenuItem
            sx={{ paddingLeft: 4 }}
            onClick={() => {
              setSelected('access-control');
              navigate('/controle-acesso');
            }}
            selected={selected === 'access-control'}
          >
            <ListItemIcon>
              <People sx={{ color: 'grey.800' }} />
            </ListItemIcon>
            <ListItemText sx={{ color: 'grey.800' }}>
              Controle de acesso
            </ListItemText>
          </MenuItem>
          <Box
            sx={{
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              justifyContent: 'flex-end',
              '& li:first-of-type': {
                ...(!open && { paddingLeft: '20px' }),
              },
            }}
          >
            <MenuItem sx={{ paddingLeft: 4 }}>
              <ListItemIcon>
                <Notifications sx={{ color: 'grey.800' }} />
              </ListItemIcon>
              <ListItemText sx={{ color: 'grey.800' }}>
                Notificações
              </ListItemText>
              <Chip label="Em breve" sx={{ color: 'grey.800' }} size="small" />
            </MenuItem>
            <MenuItem sx={{ paddingLeft: 4 }}>
              <ListItemIcon>
                <Settings sx={{ color: 'grey.800' }} />
              </ListItemIcon>
              <ListItemText sx={{ color: 'grey.800' }}>
                Configurações
              </ListItemText>
              <Chip label="Em breve" sx={{ color: 'grey.800' }} size="small" />
            </MenuItem>
            <MenuItem sx={{ paddingLeft: 4 }} onClick={() => logout()}>
              <ListItemIcon>
                <ExitToApp sx={{ color: 'grey.800' }} />
              </ListItemIcon>
              <ListItemText sx={{ color: 'grey.800' }}>Sair</ListItemText>
            </MenuItem>
          </Box>
        </MenuList>
      </Box>
    </Drawer>
  );
};

export default DrawerMenu;
