import { CheckBox } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { useState } from 'react';

import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';
import { grey } from '@mui/material/colors';

type LibraryCardProps = {
  url: string;
  name: string;
  id: string;
  code: string;
  maxHeight: string;
  isUploadMode?: boolean;
  isLoading?: boolean;
  progress?: number;
};

const LibraryCard = ({
  url,
  name,
  id,
  code,
  maxHeight,
  isUploadMode,
  isLoading,
  progress,
}: LibraryCardProps) => {
  const [show, setShow] = useState(false);
  return (
    <Box
      id={id}
      draggable={false}
      className="selectable-nodes"
      position="relative"
      sx={{
        display: show ? 'block' : 'none',
        height: maxHeight,
        maxWidth: '300px',
        overflow: 'hidden',
        '&.ds-selected': {
          outline: '2px solid #03A9F4',
          svg: {
            display: 'block',
          },
        },
        '&:hover': {
          cursor: 'pointer',
        },
        img: {
          maxWidth: '300px',
          height: maxHeight,
          width: 'auto',
          objectFit: 'cover',
          filter: isUploadMode ? 'opacity(30%);' : 'none',
        },
      }}
    >
      <img
        src={url}
        alt={name}
        onLoad={() => setShow(true)}
        draggable={false}
      />
      {!isUploadMode && (
        <Box
          sx={{
            position: 'absolute',
            top: 5,
            right: 5,
            height: 24,
            width: 24,
            zIndex: 1,
            backgroundColor: 'white',
            borderRadius: 1,
          }}
        >
          <CheckBox
            color="info"
            sx={{
              display: 'none',
              position: 'absolute',
              top: -4,
              right: -4,
              height: 32,
              width: 32,
              zIndex: 2,
            }}
          />
        </Box>
      )}
      {code && (
        <Box
          sx={{
            position: 'absolute',
            top: 20,
            left: 0,
            width: 'fit-content',
            backgroundColor: 'rgba(43, 43, 43, 0.6)',
            paddingX: 1,
            borderTopRightRadius: 16,
            borderBottomRightRadius: 16,
          }}
        >
          <Typography
            variant="caption"
            sx={{
              color: 'white',
            }}
          >
            {code}
          </Typography>
        </Box>
      )}
      {isUploadMode && (
        <Box
          position="absolute"
          top={`calc(calc(${maxHeight} / 2) - 24px)`}
          left={0}
          width="100%"
          padding={2}
        >
          <LinearProgress
            sx={{
              height: 10,
              borderRadius: 5,
              [`&.${linearProgressClasses.colorPrimary}`]: {
                backgroundColor: 'grey.200',
              },
              [`& .${linearProgressClasses.bar}`]: {
                borderRadius: 5,
                background: isLoading
                  ? 'linear-gradient(90deg, rgba(9,121,61,1) 0%, rgba(0,255,98,1) 100%);'
                  : 'linear-gradient(90deg, rgba(9,9,121,1) 0%, rgba(0,212,255,1) 100%);',
              },
            }}
            {...(isLoading && {
              variant: 'determinate',
              value: progress || 0,
            })}
          />
        </Box>
      )}
    </Box>
  );
};

export default LibraryCard;
