import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ICategory, IGetCategory } from 'interfaces';

interface CategoriesState {
  total: number;
  lastPage: number;
  loading: boolean;
  categories: ICategory[];
}

const initialState: CategoriesState = {
  total: 0,
  lastPage: 0,
  loading: false,
  categories: [],
};

export const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    SET_LOADING: (state, { payload }: { payload: boolean }) => {
      state.loading = payload;
    },
    DELETE_CATEGORY: (state, action: PayloadAction<ICategory>) => {
      state.categories = state.categories.filter(
        (cat) => cat.id !== action.payload.id
      );
    },
    UPDATE_CATEGORY: (state, action: PayloadAction<ICategory>) => {
      state.categories = state.categories.map((cat) =>
        cat.id === action.payload.id ? action.payload : cat
      );
      state.loading = false;
    },
    SET_CATEGORIES_SUCCESS: (state, action: PayloadAction<IGetCategory>) => {
      state.total = action.payload.total;
      state.lastPage = action.payload.lastPage;
      state.categories = action.payload.categories;
      state.loading = false;
    },
  },
});

export const {
  SET_LOADING,
  SET_CATEGORIES_SUCCESS,
  DELETE_CATEGORY,
  UPDATE_CATEGORY,
} = categoriesSlice.actions;

export default categoriesSlice.reducer;
