import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { IGetUser, IUserControl } from 'interfaces';

interface AccessControlState {
  total: number;
  lastPage: number;
  users: IUserControl[];
  loading: boolean;
}

const initialState: AccessControlState = {
  total: 0,
  lastPage: 0,
  users: [],
  loading: false,
};

export const accessControlSlice = createSlice({
  name: 'accessControl',
  initialState,
  reducers: {
    DELETE_USER: (state, action: PayloadAction<IUserControl>) => {
      state.users = state.users.filter(
        (user) => user.email !== action.payload.email
      );
    },
    UPDATE_USER: (state, action: PayloadAction<IUserControl>) => {
      state.users = state.users.map((user) =>
        user.id === action.payload.id ? action.payload : user
      );
      state.loading = false;
    },
    SET_USERS: (state, action: PayloadAction<IGetUser>) => {
      state.total = action.payload.total;
      state.lastPage = action.payload.lastPage;
      state.users = action.payload.users;
      state.loading = false;
    },
    SET_USER_LOADING: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
});

export const { DELETE_USER, UPDATE_USER, SET_USERS, SET_USER_LOADING } =
  accessControlSlice.actions;

export default accessControlSlice.reducer;
