import { useEffect, useCallback, useRef, useState } from 'react';
import { Box, Button, Link, Stack, Typography } from '@mui/material';
import InputImage from '../InputImage';
import { useLocales, useUpload } from 'hooks';

interface FileUploadProps {
  show: boolean;
  isFixed?: boolean;
  onClose?: () => void;
  inputFiles: FileWithUrl[];
  setInputFiles: React.Dispatch<React.SetStateAction<FileWithUrl[]>>;
}

export interface FileWithUrl {
  file: File;
  url: string;
}

const FileUpload = ({
  show,
  isFixed,
  onClose,
  inputFiles,
  setInputFiles,
}: FileUploadProps) => {
  const { localeInfo } = useLocales();
  const { startUpload } = useUpload();
  const containerRef = useRef<HTMLInputElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [inputHeigth, setInputHeight] = useState<number>(0);

  const handleFileInputClick = useCallback(() => {
    fileInputRef.current?.click();
  }, []);

  const handleFiles = useCallback(
    (files: FileList | File[]) => {
      const validFiles = Array.from(files).filter((file) =>
        file.type.includes('image')
      );

      setInputFiles((prev) => {
        const filteredNewFiles = validFiles.filter(
          (validFile) => !prev.some((file) => file.file.name === validFile.name)
        );
        return [
          ...prev,
          ...filteredNewFiles.map((file) => ({
            file,
            url: URL.createObjectURL(file),
          })),
        ];
      });
    },
    [setInputFiles]
  );

  const handleDrop = useCallback(
    async (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();

      const processEntry = async (entry: FileSystemEntry): Promise<File[]> => {
        if (entry.isFile) {
          return new Promise((resolve) => {
            (entry as FileSystemFileEntry).file((file) => {
              resolve([file]);
            });
          });
        } else if (entry.isDirectory) {
          const reader = (entry as FileSystemDirectoryEntry).createReader();
          return new Promise((resolve) => {
            reader.readEntries(async (entries) => {
              const files = await Promise.all(entries.map(processEntry));
              resolve(files.flat());
            });
          });
        }
        return [];
      };

      const items = e.dataTransfer.items;
      const filePromises: Promise<File[]>[] = [];

      for (let i = 0; i < items.length; i++) {
        const entry = items[i].webkitGetAsEntry();
        if (entry) {
          filePromises.push(processEntry(entry));
        }
      }

      const files = await Promise.all(filePromises);

      handleFiles(files.flat());
      e.dataTransfer.clearData();
    },
    [handleFiles]
  );

  const onChangeFiles = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        handleFiles(e.target.files);
      }
    },
    [handleFiles]
  );

  const onDeleteFile = useCallback(
    (imgName: string) => {
      setInputFiles((prev) => {
        const newFiles = prev.filter((file) => {
          if (file.file.name === imgName) {
            URL.revokeObjectURL(file.url);
            return false;
          }
          return true;
        });
        if (newFiles.length === 0) {
          onClose?.();
        }
        return newFiles;
      });
    },
    [onClose, setInputFiles]
  );

  useEffect(() => {
    const rect = containerRef.current?.getBoundingClientRect();

    if (rect?.height) {
      setInputHeight(rect.height);
    }
  }, [show]);

  return (
    <Box
      width="100%"
      height="100%"
      display={isFixed || show || inputFiles.length ? 'flex' : 'none'}
      padding={2}
      paddingBottom={isFixed ? 2 : 12}
      onDrop={handleDrop}
    >
      <Stack
        position="relative"
        ref={containerRef}
        width="100%"
        overflow="hidden"
        maxHeight={inputHeigth || '100%'}
        {...(isFixed && { minHeight: '350px' })}
        justifyContent="center"
        alignItems="center"
        sx={{
          borderColor: 'divider',
          borderStyle: 'dashed',
          borderWidth: 1,
          borderRadius: 1,
          borderSpacing: 2,
          paddingRight: 1,
        }}
        onClick={() => {
          if (!inputFiles.length) {
            handleFileInputClick();
          }
        }}
      >
        {inputFiles.length ? (
          <>
            <Stack
              display="flex"
              flex={1}
              paddingLeft={3}
              paddingRight={3}
              paddingTop={3}
              overflow="auto"
              direction="row"
              flexWrap="wrap"
              justifyContent="flex-start"
              rowGap={3}
              columnGap={3}
              height="100%"
              width="100%"
              marginBottom={7}
              sx={{
                userSelect: 'none',
                '&::-webkit-scrollbar': {
                  width: 10,
                },
                '&::-webkit-scrollbar-track': {
                  borderRadius: 10,
                  backgroundColor: 'grey.100',
                },
                '&::-webkit-scrollbar-thumb': {
                  borderRadius: '8px',
                  backgroundColor: 'grey.800',
                },
              }}
            >
              {inputFiles.map((file) => (
                <InputImage
                  key={file.file.name}
                  name={file.file.name}
                  url={file.url}
                  onDelete={() => onDeleteFile(file.file.name)}
                />
              ))}
            </Stack>
            {inputFiles.length && (
              <Stack
                position="absolute"
                bottom={0}
                justifySelf="flex-end"
                direction="row"
                padding={1}
                width="100%"
                justifyContent="flex-end"
                bgcolor="white"
              >
                <Button
                  variant="outlined"
                  sx={{ mr: 2 }}
                  onClick={handleFileInputClick}
                >
                  Adicionar mais
                </Button>
                {!isFixed && (
                  <Button
                    variant="contained"
                    onClick={() => {
                      startUpload(
                        localeInfo!.name,
                        localeInfo!.id,
                        localeInfo!.code,
                        inputFiles
                      );
                      onClose?.();
                      setInputFiles([]);
                    }}
                  >
                    Upload
                  </Button>
                )}
              </Stack>
            )}
          </>
        ) : (
          <>
            <Typography variant="subtitle1" color="primary" fontSize={18}>
              <Link color="info.dark">Faça upload de um arquivo</Link> ou
              arraste uma imagem para cá
            </Typography>
          </>
        )}
      </Stack>
      <input
        ref={fileInputRef}
        type="file"
        id="fileElem"
        multiple
        accept="image/*"
        onChange={onChangeFiles}
        style={{ display: 'none' }}
      />
    </Box>
  );
};

export default FileUpload;
