export interface IAddUser {
  name: string;
  email: string;
  role: string;
}

export interface IUserControl {
  id: string;
  name: string;
  email: string;
  role: UserRoles;
  firstAccess: boolean;
}

export interface INewPassword {
  newPassword: string;
}

export interface IGetUser {
  total: number;
  lastPage: number;
  users: IUserControl[];
}

export enum UserRoles {
  ADMIN = 'Administrador',
  PHOTOGRAPHER = 'Fotógrafo',
  VIEWER = 'Visualizador',
}
